<template>
  <div class="cs-content">
    <h3 class="cs-title">实验项目</h3>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="实验项目" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-btn btn-search" @click="getProjectList">搜索</div>
      <div class="cs-btn btn-add" @click="addProject">新建实验项目</div>
    </div>
    <div class="cs-table">
      <el-table :data="projectList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="theName" label="实验项目"></el-table-column>
        <el-table-column prop="theContent" label="项目说明"></el-table-column>
        <el-table-column label="操作">
          <template scope="scope">
            <div class="td-btns text-success" @click="editProject(scope.row)">编辑</div>
            <div class="line">|</div>
            <div class="td-btns text-danger" @click="delProject(scope.row.tableId)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15">
        <span class="dialog-title">实验室：</span>
        <div class="dialog-input">
          <el-cascader
                  v-model="laboratoryArr"
                  :options="regionList"
                  :show-all-levels="false"
                  :props="props"
                  clearable>
          </el-cascader>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">实验项目：</span>
        <div class="dialog-input">
          <el-input type="text" placeholder="请输入实验项目名称" size="small" v-model="theName"></el-input>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">项目说明：</span>
        <div class="dialog-input">
          <el-input type="textarea" :autosize="{ minRows: 3}" placeholder="请输入项目说明" v-model="theContent"></el-input>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,Select,Option,Cascader} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Cascader)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "projectList",
    components: {TipDialog},
    data() {
      return {
        keyword:'',
        projectList: [],
        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage:0,

        handleTitle:'删除',
        handleInfo:'您确定要删除该实验项目吗？',

        cuDialogTitle: "",
        cuDialogShow:false,
        cuDialogType: "",
        tableId: "",
        laboratoryArr: [],
        regionList: [],
        props: {
          multiple: true, //是否多选
          expandTrigger: 'hover', //触发选择方式
          value: "tableId", //选择的值
          label: "theName", //展示的选择项
          children: "children", //子集合
        },
        theName: "",
        theContent: "",

        idArr: []
      }
    },
    mounted() {
      this.getRegionForSelectList();
      this.getProjectList();
    },
    methods:{
      getRegionForSelectList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saRegionForSelectList.sahtml', model, jsonObj => {
          console.log("地区列表");
          console.log(jsonObj);
          this.regionList = jsonObj.regionList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getProjectList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
        }

        this.$api.post('/saProjectList.sahtml', model, jsonObj => {
          console.log("项目列表");
          console.log(jsonObj);
          this.projectList = jsonObj.projectList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      addProject() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建实验项目";
        this.cuDialogType = "create";
      },
      editProject(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "编辑实验项目";
        this.cuDialogType = "update";
        this.tableId = obj.tableId;
        this.laboratoryArr = obj.laboratoryArr;
        this.theName = obj.theName;
        this.theContent = obj.theContent;

      },
      cancel() {
        this.$refs.topDialog.hide();
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.laboratoryArr = [];
        this.theName = "";
        this.theContent = "";
        this.idArr = [];
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saProjectAdd.sahtml' : '/saProjectUpdate.sahtml';
        var laboratoryIdArr = [];
        for (var i=0;i<this.laboratoryArr.length;i++) {
          laboratoryIdArr.push(this.laboratoryArr[i][2]);
        }
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          laboratoryIdArr: laboratoryIdArr,
          theName: this.theName,
          theContent: this.theContent,
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getProjectList();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getProjectList();
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saProjectDelete.sahtml', model, jsonObj => {
          this.cancel();
          this.getProjectList();
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      delProject(tableId){
        this.$refs.topDialog.show()
        this.idArr.push(tableId);
      }
    }
  }
</script>
<style scoped lang="less">
  .btn-add{
    float: right;
  }
  .cs-input{
    width: 200px;
  }
</style>
