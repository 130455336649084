<template>
	<div class="cs-content">
    <h3 class="cs-title">项目开放状态<span class="small-text">（默认全部是开放状态，当前页面只展示关闭状态的实验项目）</span></h3>
    <div class="cs-search">
      <div class="cs-sel">
        <div class="cs-ml sel-width">
          <el-select v-model="regionSearchId" clearable filterable placeholder="请选择地区" size="small" @change="getBranchSearchList">
            <el-option
                v-for="item in regionSearchList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
        <div class="cs-ml sel-width">
          <el-select v-model="branchSearchId" clearable filterable placeholder="请选择分公司" size="small" @change="getLaboratorySearchList">
            <el-option
                    v-for="item in branchSearchList"
                    :key="item.tableId"
                    :label="item.theName"
                    :value="item.tableId">
            </el-option>
          </el-select>
        </div>
        <div class="cs-ml sel-width">
          <el-select v-model="laboratorySearchId" clearable filterable placeholder="请选择实验室" size="small" @change="getProjectSearchList">
            <el-option
                v-for="item in laboratorySearchList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
        <div class="sel-width">
          <el-select v-model="projectSearchId" clearable filterable placeholder="请选择项目" size="small">
            <el-option
                v-for="item in projectSearchList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-date">
        <span class="text">日期：</span>
        <el-date-picker size="small" value-format="yyyy-MM-dd" v-model="searchDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </div>
      <div class="cs-btn btn-search" @click="getProjectOpenInfoList">搜索</div>
      <div class="cs-btn btn-add" @click="addProjectOpenInfo">新建关闭项目</div>
    </div>
    <div class="cs-table">
      <el-table :data="projectOpenInfoList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center" fixed>
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="regionName" label="地区" width="120" fixed></el-table-column>
        <el-table-column prop="branchName" label="分公司" width="200"></el-table-column>
        <el-table-column prop="laboratoryName" label="实验室" width="180"></el-table-column>
        <el-table-column prop="projectName" label="实验项目" width="230"></el-table-column>
        <el-table-column prop="theDateStr" label="日期" width="120"></el-table-column>
        <el-table-column prop="theTimePeriod" label="时间段" width="120"></el-table-column>
        <el-table-column label="状态" width="90">
          <template scope="scope">
            <div class="cs-btn btn-close" v-if="scope.row.isOpen == 2">关闭</div>
          </template>
        </el-table-column>
        <el-table-column prop="theRemark" label="备注" min-width="200"></el-table-column>
        <el-table-column label="操作" width="160">
          <template scope="scope">
            <div class="td-btns text-success" @click="editProjectOpenInfo(scope.row)">编辑</div>
            <div class="line">|</div>
            <div class="td-btns text-success" @click="delProjectOpenInfo(scope.row.tableId)">重新开放</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">实验室：</span>
        <div class="dialog-input">
          <el-cascader @change="getProjectList"
                  v-model="laboratoryArr"
                  :options="regionList"
                  :show-all-levels="false"
                  :props="props"
                  clearable>
          </el-cascader>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">实验项目：</span>
        <div class="dialog-input">
          <el-select v-model="projectId" filterable placeholder="请选择实验项目" size="small">
            <el-option
                v-for="item in projectList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">日期：</span>
        <div class="dialog-input">
          <el-date-picker
              v-model="theDateStr"
              type="date"
              value-format="yyyy-MM-dd"
              size="small"
              placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">时间段：</span>
        <div class="dialog-input">
          <div class="time">
            <el-time-select placeholder="起始时间" size="small" v-model="theTimeStartStr" :picker-options="{start: '00:00',step: '00:30',end: '24:00'}"></el-time-select>
          </div>
          <div class="mark">~</div>
          <div class="time">
            <el-time-select placeholder="结束时间" size="small" v-model="theTimeEndStr" :picker-options="{start: '00:00',step: '00:30',end: '24:00',minTime:theTimeStartStr}"></el-time-select>
          </div>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">备注：</span>
        <div class="dialog-input">
          <el-input type="textarea" :autosize="{ minRows: 3}" placeholder="请输入用途说明" v-model="theRemark"></el-input>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
	</div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,Switch,Select,Option,DatePicker} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Switch)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(DatePicker)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "courseScheduleList",
    components: {TipDialog},
    data() {
      return {
        regionSearchId: '',
        regionSearchList: [],
        branchSearchId: '',
        branchSearchList: [],
        laboratorySearchId: '',
        laboratorySearchList: [],
        projectSearchId: '',
        projectSearchList: [],
        searchDate: "",
        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage:0,
        projectOpenInfoList: [],

        cuDialogShow: false,
        cuDialogTitle: "",
        cuDialogType: "",
        tableId: "",
        laboratoryArr: [],
        regionList: [],
        props: {
          expandTrigger: 'hover', //触发选择方式
          value: "tableId", //选择的值
          label: "theName", //展示的选择项
          children: "children", //子集合
        },
        projectId: "",
        projectList: [],
        theDateStr: "",
        theTimeStartStr: "",
        theTimeEndStr: "",
        theRemark: "",

        handleTitle:'重新开放',
        handleInfo:'您确定要开放该项目吗？',

        idArr: []
      }
    },
    mounted() {
      this.getRegionSearchList();
      this.getRegionForSelectList();
      this.getProjectOpenInfoList();
    },
    methods:{
      getRegionForSelectList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saRegionForSelectList.sahtml', model, jsonObj => {
          console.log("地区列表");
          console.log(jsonObj);
          this.regionList = jsonObj.regionList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getRegionSearchList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saRegionList.sahtml', model, jsonObj => {
          console.log("搜索地区列表");
          console.log(jsonObj);
          this.regionSearchList = jsonObj.regionList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getBranchSearchList() {
        this.branchSearchId = "";
        this.laboratorySearchId = "";
        this.projectSearchId = "";
        let model = {
          interfaceVersion: 19000101,
          regionId: this.regionSearchId,
        }

        this.$api.post('/saBranchList.sahtml', model, jsonObj => {
          console.log("分公司列表");
          console.log(jsonObj);
          this.branchSearchList = jsonObj.branchList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getLaboratorySearchList() {
        this.laboratorySearchId = "";
        this.projectSearchId = "";
        let model = {
          interfaceVersion: 19000101,
          branchId: this.branchSearchId
        }

        this.$api.post('/saLaboratoryList.sahtml', model, jsonObj => {
          console.log("实验室列表");
          console.log(jsonObj);
          this.laboratorySearchList = jsonObj.laboratoryList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getProjectSearchList() {
        this.projectSearchId = "";
        let model = {
          interfaceVersion: 19000101,
          laboratoryId: this.laboratorySearchId
        }

        this.$api.post('/saProjectList.sahtml', model, jsonObj => {
          console.log("项目列表");
          console.log(jsonObj);
          this.projectSearchList = jsonObj.projectList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getProjectOpenInfoList() {
        let model = {
          interfaceVersion: 19000101,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
          regionId: this.regionSearchId,
          branchId: this.branchSearchId,
          laboratoryId: this.laboratorySearchId,
          projectId: this.projectSearchId,
          searchBeginTimeStr: this.searchDate != null && this.searchDate[0] != undefined ? this.searchDate[0] : null,
          searchEndTimeStr: this.searchDate != null && this.searchDate[1] != undefined ? this.searchDate[1] : null,
        }

        this.$api.post('/saLaboratoryAndProjectOpenInfoList.sahtml', model, jsonObj => {
          console.log("关闭项目列表");
          console.log(jsonObj);
          this.projectOpenInfoList = jsonObj.laboratoryAndProjectOpenInfoList;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      addProjectOpenInfo() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建关闭项目";
        this.cuDialogType = "create";
      },
      editProjectOpenInfo(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "编辑关闭项目";
        this.cuDialogType = "update";
        this.tableId = obj.tableId;
        this.theRemark = obj.theRemark;
      },
      getProjectList() {
        let model = {
          interfaceVersion: 19000101,
          laboratoryId: this.laboratoryArr != null && this.laboratoryArr[2] != undefined ? this.laboratoryArr[2] : null
        }

        this.$api.post('/saProjectList.sahtml', model, jsonObj => {
          console.log("项目列表");
          console.log(jsonObj);
          this.projectList = jsonObj.projectList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      cancel() {
        this.$refs.topDialog.hide();
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.laboratoryArr = [];
        this.projectId = "";
        this.theDateStr = "";
        this.theTimeStartStr = "";
        this.theTimeEndStr = "";
        this.theRemark = "";
        this.idArr = [];
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saLaboratoryAndProjectOpenInfoAdd.sahtml' : '/saLaboratoryAndProjectOpenInfoUpdate.sahtml';

        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          laboratoryId: this.laboratoryArr[2] != null && this.laboratoryArr[2] != undefined ? this.laboratoryArr[2] : null,
          projectId: this.projectId,
          theDateStr: this.theDateStr,
          theTimeStartStr: this.theTimeStartStr,
          theTimeEndStr: this.theTimeEndStr,
          theRemark: this.theRemark
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getProjectOpenInfoList();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getProjectOpenInfoList();
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saLaboratoryAndProjectOpenInfoDelete.sahtml', model, jsonObj => {
          this.cancel();
          this.getProjectOpenInfoList();
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      delProjectOpenInfo(tableId){
        this.$refs.topDialog.show();
        this.idArr.push(tableId);
      }
    }
  }
</script>
<style scoped lang="less">
	@import "../assets/css/common.less";
  .btn-add{
    float: right;
  }
  .dialog-title{
    &.switch{
      margin-top: 0;
    }
  }
  .dialog-input{
    .mark{
      width: 30px;
      display: #display[i];
      text-align: center;
    }
    .time{
      width: 150px;
      width: calc(50% - 15px);
      display: #display[i];
    }
  }
</style>
