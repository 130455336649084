/**
 * 在该文件中定义全局过滤器，在需要用到过滤器的页面对应的 main.js 中引入， 并注册全局过滤器
 */
import el from "element-ui/src/locale/lang/el";

const dfilters = {
    /**
     * 空值处理
     */
    valNullHandler : function(value, placeholder) {
        if (value == null || value == undefined || (value+"").length < 1) return placeholder ? placeholder:"";
        else return value;
    },
    /**
     * 数字空值处理
     */
    valNumberNullHandler : function(value, placeholder) {
        if (value == null || value == undefined || (value+"").length < 1 || isNaN(value)) return placeholder ? placeholder:"";
        else return value;
    },
    /**
     * 小数点后指定位数
     *
     * @param value
     * @param decimal
     */
    showFloat : function(value,decimal) {
        if (value == null || (value+"").length < 1 || isNaN(value)) return "";
        if(decimal == null || (value+"").length < 1 || isNaN(value)) decimal = 2;
        return Math.round(value*Math.pow(10,decimal))/Math.pow(10,decimal);
    },
    /**
     * json数组中的某个指定的字段，按一定格式拼接成字符串
     *
     * @param value 原始值JsonArray格式
     * @param fieldName JSONArray中某个对象的某个key
     * @param separator 分隔符（list中每个对象之间的分割符）
     * @param type 类型
     */
    jsonArrayJoin : function(value, fieldName, separator, type) {
        if(value == null || value == undefined) return "";

        if(!(typeof(value) == 'string'))
        {
            value = JSON.stringify(value)
        }
        if((value+"").length < 1 || !isJSON(value)) return "";

        let objArr = JSON.parse(value)
        // console.info(objArr)

        let val = ""
        for(let index in objArr) {
            if(index == objArr.length -1)
            {
                val += valueOfChain(objArr[index],fieldName,type)
            }
            else
            {
                val += valueOfChain(objArr[index],fieldName,type)+separator
            }
        }
        return val
    },
    /**
     * 获取显示的信息
     *
     * @param value 源数据
     * @param showFileName 需要展示的字段
     * @param checkFieldName 需要校验的字段
     * @param dataList 列表数据
     */
    getNameFromList(value, showFileName, checkFieldName, dataList)
    {
        if(value == null || (value+"").length < 1 || dataList == null || dataList.length == 0) return "";

        let name = ""
        dataList.forEach((item) => {
            if(value === item[checkFieldName])
            {
                name = item[showFileName]
                return
            }
        })
        return name;
    },
    /**
     * 获取显示的信息
     * （求和后显示）
     *
     * @param valueArr 需要统计的条件（当校验字段的值为？时，需要累加）
     * @param sumField 求和的字段
     * @param sumFieldType 字段的类型 例如 int float
     * @param checkFieldName 校验字段
     * @param dataList 列表数据
     */
    getTotalFromList(valueArr, sumField, sumFieldType, checkFieldName, dataList)
    {
        if(dataList == null || dataList.length == 0) return "0";

        let sunVal = 0
        dataList.forEach((item) => {
            let tempVal = 0
            if(sumFieldType == 'int')
            {
                tempVal = parseInt(item[sumField])
            }
            else if(sumFieldType == 'float')
            {
                tempVal = parseFloat(item[sumField])
            }

            if(valueArr != null && valueArr.length > 0 && (valueArr.indexOf(item[checkFieldName]) > -1))
            {
                sunVal += tempVal
            }
            else if(valueArr == null || valueArr.length == 0)
            {
                sunVal += tempVal
            }
        })

        return sunVal;
    },
}
export default dfilters;

/**
 * 判断是否是JSON
 *
 * @param str
 * @returns {boolean}
 */
function isJSON(str) {
    if (typeof str == 'string') {
        try {
            let obj=JSON.parse(str);
            if(typeof obj == 'object' && obj ){
                return true;
            }else{
                return false;
            }
        } catch(e) {
            return false;
        }
    }
}

Date.prototype.Format = function (fmt) {
    let o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

//============================= 深度获取JSON对象中的值 Start =======================================//
// 各个类型0值的默认值
// vtype取值为字符串,string, int, bool
// 使用示例:
// zeroDefault('string')
function zeroDefault(vtype) {
    switch (vtype) {
        case 'string':case 'varchar':case 'char':
            return ''
        case 'int':case 'number':case 'float':
            return 0
        case 'bool':case 'boolean':
            return false
        case 'struct':case 'object':
            return {}
    }
    return ''
}

// 判断某个结构体，是否有某个key
// data是{}对象，key是字符串
// 返回bool值
// 使用示例:
// existKey({name: 'ft' class: {'classname': 'et'}}, 'class')
function existKey(data, key) {
    if (data.hasOwnProperty(key)) {
        return true
    }
    return false
}

// 使用示例:
// valueOfChain({name: 'ft' class: {'classname': 'et'}}, 'class.classname', 'object')
function valueOf(data, vtype) {
    if (typeof data == 'undefined' || data === null) {
        return zeroDefault(vtype)
    }
    return data
}

// data是{}对象，深度未知
// chain是字符串，格式必须为类似 user_info.class.class_info 这样的链式
// vtype是字符串，取值为string, int, bool
// 使用示例:
// valueOfChain({name: 'ft' class: {'classname': 'et'}}, 'class.classname', 'string')
function valueOfChain(data, chain, vtype) {
    var fields = chain.split('.')
    if (fields.length < 1) {
        return zeroDefault(vtype)
    }
    if (existKey(data, fields[0])) {
        if (fields.length == 1) {
            return valueOf(data[fields[0]])
        } else {
            //   valueOf({classname: 'et'}, 'classname', 'string')
            // ['classname'].join('.') = 'classname'
            return valueOfChain(
                data[fields[0]],
                fields.slice(1).join('.'),
                vtype
            )
        }
    }
    return zeroDefault(vtype)
}
//============================= 深度获取JSON对象中的值 End =======================================//
