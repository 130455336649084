<template>
	<div class="cs-content">
    <h3 class="cs-title">消息记录</h3>
    <div class="tab-wrap">
      <ul class="tab-state">
        <li @click="selList = 1" :class="{'active': selList==1}">推送通知</li>
        <li @click="selList = 2" :class="{'active': selList==2}">邮件通知</li>
      </ul>
    </div>
    <push-list v-if="selList == 1"></push-list>
    <email-list v-if="selList == 2"></email-list>
	</div>
</template>
<script>
  import Vue from 'vue';
  import pushList from ".././components/pushRecordList";
  import emailList from ".././components/emailList";

  export default {
    name: "messageList",
    components: {pushList,emailList},
    data() {
      return {
        selList:1
      }
    },
    methods:{

    }
  }
</script>
<style scoped lang="less">
	@import "../assets/css/common.less";
  .tab-wrap{
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;
    .tab-state{
      display: #display[i];
      li{
        display: #display[i];
        color: #colors[defaultText];
        padding: 0 10px 15px 10px;
        margin-right: 40px;
        cursor: pointer;
        position: relative;
      }
      .active{
        color: #colors[primary];
        border-bottom: 2px solid #colors[primary];
      }
    }
  }
</style>
