<template>
  <div class="cs-content">
    <h3 class="cs-title">实验室</h3>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="实验室" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-sel">
        <span class="text">实验室：</span>
        <div class="cs-ml sel-width">
          <el-select v-model="regionSearchId" clearable filterable placeholder="请选择地区" size="small" @change="getSearchBranchForSelectList">
            <el-option
                v-for="item in regionList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
        <div class="sel-room">
          <el-select v-model="branchSearchId" clearable filterable placeholder="请选择分公司" size="small">
            <el-option
                v-for="item in branchSearchList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-btn btn-search" @click="getLaboratoryList">搜索</div>
      <div class="cs-btn btn-add" @click="addLaboratory">新建实验室</div>
    </div>
    <div class="cs-table">
      <el-table :data="laboratoryList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="regionName" label="所属地区" width="160"></el-table-column>
        <el-table-column prop="branchName" label="所属分公司" width="240"></el-table-column>
        <el-table-column prop="theName" label="实验室" width="160"></el-table-column>
        <el-table-column prop="verificationService" label="主要检测服务" min-width="230"></el-table-column>
        <el-table-column label="操作" width="200">
          <template scope="scope">
            <div class="td-btns text-success" @click="editLaboratory(scope.row)">编辑</div>
            <div class="line">|</div>
            <div class="td-btns text-danger" @click="delLaboratory(scope.row.tableId)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15">
        <span class="dialog-title">所属地区：</span>
        <div class="dialog-input">
          <el-select v-model="regionId" filterable placeholder="请选择地区" size="small" @change="getBranchForSelectList">
            <el-option
                v-for="item in regionList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">所属分公司：</span>
        <div class="dialog-input">
          <el-select v-model="branchId" filterable placeholder="请选择分公司" size="small">
            <el-option
                v-for="item in branchList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">实验室：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入实验室名称" size="small" v-model="theName"></el-input>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">检测服务：</span>
        <div class="dialog-input">
          <el-input type="textarea" :autosize="{ minRows: 3}" placeholder="请输入检测服务" v-model="verificationService"></el-input>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,Select,Option} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Select)
  Vue.use(Option)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "laboratoryList",
    components: {TipDialog},
    data() {
      return {
        keyword: '',
        regionSearchId:'',
        regionList: [],
        branchSearchId:'',
        branchSearchList: [],
        branchList: [],
        laboratoryList: [],
        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage: 0,

        handleTitle:'删除',
        handleInfo:'您确定要删除该实验室吗？',

        cuDialogShow: false,
        cuDialogTitle: "",
        cuDialogType: "",

        tableId: null,
        regionId: "",
        branchId: "",
        theName: "",
        verificationService: "",

        idArr: []
      }
    },
    mounted() {
      this.getRegionForSelectList();
      this.getLaboratoryList();
    },
    methods:{
      getRegionForSelectList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saRegionList.sahtml', model, jsonObj => {
          console.log("地区列表");
          console.log(jsonObj);
          this.regionList = jsonObj.regionList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getSearchBranchForSelectList() {
        this.branchSearchId = "";
        let model = {
          interfaceVersion: 19000101,
          regionId: this.regionSearchId,
        }

        this.$api.post('/saBranchList.sahtml', model, jsonObj => {
          console.log("分公司查询列表");
          console.log(jsonObj);
          this.branchSearchList = jsonObj.branchList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      async getBranchForSelectList() {
        return new Promise((res,rej) => {
          this.branchId = "";
          let model = {
            interfaceVersion: 19000101,
            regionId: this.regionId,
          }

          this.$api.post('/saBranchList.sahtml', model, jsonObj => {
            // console.log("分公司列表");
            // console.log(jsonObj);
            this.branchList = jsonObj.branchList;
            res('成功')//必须要加不然不会生效
          },jsonObj => {
            // console.log(jsonObj);
            rej('失败')//必须要加不然不会生效
            this.$message.error(jsonObj.info);
          })
        })
      },
      getLaboratoryList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
          regionId: this.regionSearchId,
          branchId: this.branchSearchId
        }

        this.$api.post('/saLaboratoryList.sahtml', model, jsonObj => {
          //console.log("实验室列表");
          //console.log(jsonObj);
          this.laboratoryList = jsonObj.laboratoryList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      addLaboratory() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建实验室";
        this.cuDialogType = "create";
      },
      async editLaboratory(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "编辑实验室";
        this.cuDialogType = "update";
        this.tableId = obj.tableId;
        this.regionId = obj.regionId;
        await this.getBranchForSelectList();
        this.branchId = obj.branchId;
        this.theName = obj.theName;
        this.verificationService = obj.verificationService;
      },
      cancel() {
        this.$refs.topDialog.hide();
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.regionId = "";
        this.branchId = "";
        this.theName = "";
        this.verificationService = "";
        this.idArr = [];
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saLaboratoryAdd.sahtml' : '/saLaboratoryUpdate.sahtml';
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          regionId: this.regionId,
          branchId: this.branchId,
          theName: this.theName,
          verificationService: this.verificationService,
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getLaboratoryList();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getLaboratoryList();
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saLaboratoryDelete.sahtml', model, jsonObj => {
          this.cancel();
          this.getLaboratoryList();
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      delLaboratory(tableId){
        this.$refs.topDialog.show()
        this.idArr.push(tableId);
      }
    }
  }
</script>
<style scoped lang="less">
  @import "../assets/css/common.less";
  .btn-add{
    float: right;
  }
  .dialog-input{
    .mark{
      width: 30px;
      display: #display[i];
      text-align: center;
    }
    .time{
      width: 150px;
      width: calc(50% - 15px);
      display: #display[i];
    }
  }
  .sel-room{
    width: 260px;
    display: inline-block;
    .el-select{
      width: 100%;
    }
  }
</style>
