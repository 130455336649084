<template>
  <div>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="标题/内容/接收人（邮箱）" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-btn btn-search" @click="getEmailRecordList">搜索</div>
    </div>
    <div class="cs-table">
      <el-table :data="emailRecordList" stripe style="width: 100%" height="calc(100vh - 342px)">
        <el-table-column type="index" label="序号" width="70" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="theTitle" label="标题" width="260"></el-table-column>
        <el-table-column prop="theContent" label="正文" min-width="280"></el-table-column>
        <el-table-column label="接收人" width="240">
          <template scope="scope">
            <!--<div>姓名：{{scope.row.marketerName}}</div>
            <div>手机：{{scope.row.marketerPhone}}</div>-->
            <div>邮箱：{{scope.row.email}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createDateTimeStamp" label="发送时间" width="200"></el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)

  export default {
    name: "push-record-list",
    data() {
      return {
        keyword:'',
        emailRecordList: [],
        pageNumber: 1,
        countPerPage: 20,
        totalCount: 0,
        totalPage: 0,
      }
    },
    mounted() {
      this.getEmailRecordList();
    },
    methods:{
      getEmailRecordList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
        }

        this.$api.post('/saEmailRecordList.sahtml', model, jsonObj => {
          console.log("邮件消息列表");
          console.log(jsonObj);
          this.emailRecordList = jsonObj.emailRecordList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getEmailRecordList();
      },
    }
  }
</script>

<style scoped lang="less">

</style>
