<template>
	<div class="cs-content">
    <h3 class="cs-title">工作日设置<span class="small-text">（未来10年默认全部是工作日，当前页面只展示非工作日）</span></h3>
    <div class="cs-search">
      <div class="cs-date">
        <span class="text">日期：</span>
        <el-date-picker size="small" value-format="yyyy-MM-dd" v-model="searchDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </div>
      <div class="cs-btn btn-search" @click="getWorkDayList">搜索</div>
      <div class="cs-btn btn-add" @click="addWorkDay">新建非工作日</div>
    </div>
    <div class="cs-table">
      <el-table :data="workDayList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="theDateTimeStamp" label="日期" width="300"></el-table-column>
        <el-table-column label="状态" width="300">
          <template scope="scope">
            <div class="cs-btn btn-close" v-if="scope.row.isWorkDay == 2">非工作日</div>
          </template>
        </el-table-column>
        <el-table-column prop="theRemark" label="备注" min-width="200"></el-table-column>
        <el-table-column label="操作" width="180">
          <template scope="scope">
            <div class="td-btns text-success" @click="editWorkDay(scope.row)">编辑</div>
            <div class="line">|</div>
            <div class="td-btns text-danger" @click="delWorkDay(scope.row.tableId)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">日期：</span>
        <div class="dialog-input">
          <el-date-picker
              v-model="dateStr"
              type="date"
              value-format="yyyy-MM-dd"
              size="small"
              placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">备注：</span>
        <div class="dialog-input">
          <el-input type="textarea" :autosize="{ minRows: 3}" placeholder="请输入备注" v-model="theRemark"></el-input>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
	</div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,Switch,Select,Option,DatePicker} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Switch)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(DatePicker)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "workDayList",
    components: {TipDialog},
    data() {
      return {
        searchDate:'',
        workDayList: [],
        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage: 0,

        handleTitle:'删除',
        handleInfo:'您确定要删除吗？',

        cuDialogShow: false,
        cuDialogTitle: "",
        cuDialogType: "",
        tableId: null,
        dateStr: "",
        theRemark: "",

        idArr: []
      }
    },
    mounted() {
      this.getWorkDayList();
    },
    methods:{
      getWorkDayList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
          searchBeginTimeStr: this.searchDate != null && this.searchDate != "" && this.searchDate[0] != undefined ? this.searchDate[0] : null,
          searchEndTimeStr: this.searchDate != null && this.searchDate != "" && this.searchDate[1] != undefined ? this.searchDate[1] : null,
        }
        console.log(this.searchDate)

        this.$api.post('/saWorkDayList.sahtml', model, jsonObj => {
          console.log("工作日列表");
          console.log(jsonObj);
          this.workDayList = jsonObj.workDayList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      addWorkDay() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建非工作日";
        this.cuDialogType = "create";
      },
      editWorkDay(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "编辑非工作日";
        this.cuDialogType = "update";
        this.tableId = obj.tableId;
        this.theRemark = obj.theRemark;
      },
      cancel() {
        this.$refs.topDialog.hide();
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.idArr = []
        this.dateStr = "";
        this.theRemark = "";
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saWorkDayAdd.sahtml' : '/saWorkDayUpdate.sahtml';
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          dateStr: this.dateStr,
          theRemark: this.theRemark,
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getWorkDayList();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getWorkDayList();
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saWorkDayDelete.sahtml', model, jsonObj => {
          this.cancel();
          this.getWorkDayList();
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      delWorkDay(tableId){
        this.$refs.topDialog.show()
        this.idArr.push(tableId);
      }
    }
  }
</script>
<style scoped lang="less">
	@import "../assets/css/common.less";
  .btn-add{
    float: right;
  }
  .dialog-title{
    &.switch{
      margin-top: 0;
    }
  }
  .dialog-input{
    .mark{
      width: 30px;
      display: #display[i];
      text-align: center;
    }
    .time{
      width: 150px;
      width: calc(50% - 15px);
      display: #display[i];
    }
  }
</style>
