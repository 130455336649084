<template>
  <div class="cs-content">
    <div class="cs-title">服务预约概览</div>
    <div class="cs-search">
      <div class="cs-sel">
        <span class="text">地区：</span>
        <div class="cs-ml sel-width">
          <el-select v-model="regionSearchId" filterable placeholder="请选择地区" size="small" @change="getLaboratoryList">
            <el-option
                v-for="item in regionList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-date">
        <span class="text">预约日期：</span>
        <el-date-picker size="small" value-format="yyyyMMdd" v-model="appointmentDate" type="date" placeholder="请选择日期" @change="getAppointmentList"></el-date-picker>
      </div>
      <!--<div class="cs-btn btn-search" @click="getAppointmentList">搜索</div>-->
    </div>
    <div class="cs-table overView">
      <el-table :data="appointmentListForShow" border style="width: 100%;" height="calc(100vh - 232px)" ref="multipleTable"
       :header-cell-style="headStyle"
       :span-method="arraySpanMethod"
       :cell-style="cellStyle">
        <el-table-column label="时间" width="160" align="center" fixed>
            <template scope="scope">
              {{scope.row.startTimeStr}} - {{scope.row.endTimeStr}}
            </template>
        </el-table-column>
        <el-table-column v-for="(laboratory, index) in laboratoryListPublic" :key="index" :label="laboratory.theName" width="200">
            <template scope="scope">
              <el-dropdown>
                <div class="company-wrap">
                  <div class="td-name" v-if="scope.row.laboratoryList['lab'+laboratory.tableId]">{{scope.row.laboratoryList["lab"+laboratory.tableId].companyName}}</div>
                  <div class="td-name" v-else></div>
                </div>
                <el-dropdown-menu v-if="scope.row.laboratoryList['lab'+laboratory.tableId]" slot="dropdown" style="padding: 0;margin: 0;border:none;">
                  <div class="info-company">
                    <div class="cs-mb-10 clearfix">
                      <span class="title">实验项目：</span>
                      <span class="info">{{scope.row.laboratoryList["lab"+laboratory.tableId].projectName}}</span>
                    </div>
                    <div class="cs-mb-10 clearfix">
                      <span class="title">用途：</span>
                      <span class="info">{{scope.row.laboratoryList["lab"+laboratory.tableId].theDescription}}</span>
                    </div>
                    <div class="cs-mb-10 clearfix">
                      <span class="title">客户名称：</span>
                      <span class="info">{{scope.row.laboratoryList["lab"+laboratory.tableId].companyName}}</span>
                    </div>
                    <!--<div class="cs-mb-10 clearfix">
                      <span class="title">联系人：</span>
                      <span class="info">{{scope.row.laboratoryList["lab"+laboratory.tableId].customerName}}</span>
                    </div>
                    <div class="cs-mb-10 clearfix">
                      <span class="title">联系人电话：</span>
                      <span class="info">{{scope.row.laboratoryList["lab"+laboratory.tableId].customerPhoneNumber}}</span>
                    </div>-->
                    <div class="cs-mb-10 clearfix">
                      <span class="title">用户：</span>
                      <span class="info">{{scope.row.laboratoryList["lab"+laboratory.tableId].marketerName}}</span>
                    </div>
                    <div class="cs-mb-10 clearfix">
                      <span class="title">用户电话：</span>
                      <span class="info">{{scope.row.laboratoryList["lab"+laboratory.tableId].marketerPhoneNumber}}</span>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,DatePicker,Select,Option,Dropdown,DropdownMenu,Loading} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(DatePicker)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Dropdown)
  Vue.use(DropdownMenu)
  Vue.use(Loading.directive)
  Vue.prototype.$loading = Loading.service

  //开启elementUi-Loading

  export default {
    name: "appointmentOverview",
    data() {
      return {
        regionSearchId:"",//从地区下拉菜单中选中的地区
        appointmentDate:'',//从日历控件中选中的日期
        timeLable:[//时间标签，每半小时一个小格，总计48个小格
            "00:00","00:30","01:00","01:30","02:00","02:30","03:00","03:30","04:00","04:30","05:00","05:30",
            "06:00","06:30","07:00","07:30","08:00","08:30","09:00","09:30","10:00","10:30","11:00","11:30",
            "12:00","12:30","13:00","13:30","14:00","14:30","15:00","15:30","16:00","16:30","17:00","17:30",
            "18:00","18:30","19:00","19:30","20:00","20:30","21:00","21:30","22:00","22:30","23:00","23:30",
            "24:00"],
        timeIndex:{//数据与timeLable对应，用于帮助快速定位数据的位置
            "00:00": 0,"00:30": 1,"01:00": 2,"01:30": 3,"02:00": 4,"02:30": 5,"03:00": 6,"03:30": 7,"04:00": 8,"04:30": 9,
            "05:00":10,"05:30":11,"06:00":12,"06:30":13,"07:00":14,"07:30":15,"08:00":16,"08:30":17,"09:00":18,"09:30":19,
            "10:00":20,"10:30":21,"11:00":22,"11:30":23,"12:00":24,"12:30":25,"13:00":26,"13:30":27,"14:00":28,"14:30":29,
            "15:00":30,"15:30":31,"16:00":32,"16:30":33,"17:00":34,"17:30":35,"18:00":36,"18:30":37,"19:00":38,"19:30":39,
            "20:00":40,"20:30":41,"21:00":42,"21:30":43,"22:00":44,"22:30":45,"23:00":46,"23:30":47
        },
        backgroundColor:["#56a967","#fbca48","#b099df","#77cccc","#a5bbf5"],//预设的几种背景色
        laboratoryListPublic:[{tableId:'',theName:""}],//实验室列表-用于绑定HTMl
        laboratoryListPublicTemp:[{tableId:'',theName:""}],//实验室列表-用于临时接收server接口数据
                                                /*
                                                 * 用于接收实验室列表接口数据
                                                 * 实验室列表更新后，还需要重新刷新预约列表数据，
                                                 * 等预约列表数据都准备好后，再同步更新实验室列表数据，不然会造成表头与表格内容错位的问题
                                                 * */
        projectList:[{tableId:'',theName:""}],//实验项目列表
        regionList:[{tableId:'',theName:''}],//区域列表
        appointmentListForShow://预约数据列表-用于图表显示
            [{
                "startTimeStr": "00:00",
                "endTimeStr": "00:30",
                laboratoryList:
                {
                    "lab1":
                    {
                        projectName:"项目名",
                        theDescription:"用途",
                        companyName:"公司名",
                        customerName:"联系人",
                        customerPhoneNumber:"联系人电话",
                        //2020-07-23 新增
                        marketerName:'用户',
                        marketerPhoneNumber:'用户电话'
                    },
                },
            }],
         appointmentListFromServer://预约数据列表-原始Server数据
            [
                {
                    //预约基础信息
                    "tableId": 108,
                    "startTimeStr": "00:00",
                    "endTimeStr": "00:30",
                    "appointmentDateStr": "2020-05-29",
                    "appointmentEndDateStr": "2020-05-29",
                    "authState": 1,
                    "theDescription": "用途说明",
                    "createTimeStamp": 1590716180006,
                    "createTimeStampStr": "2020-05-29 09:36:20",

                    //关联实验室
                    "laboratoryId": 23,
                    "laboratoryName": "AR5",

                    //关联项目
                    "projectId":1,
                    "projectName":"电快速瞬变脉冲群抗扰度测试",

                    //关联客户
                    "customerId": 1,
                    "companyName": "dct公司",
                    "companyTel": "18911111111",
                    "customerName": "dct",
                    "customerPhoneNumber": "18911111112",
                    "customerPhoneNumberFormat": "189-1111-1112",
                    "customerEmail": "1@1.com",
                    "customerAttribute": "客户属性1",
                    "department": "联系部",
                    "position": "大佬",

                    //关联用户
                    "marketerId": 7,
                    "marketerName": "Luna",
                    "marketerEmail": "123@qq.com",
                    "marketerPhoneNumber": "18722222222",
                    "marketerPhoneNumberFormat": "187-2222-2222",
                },
            ],
         appointmentListForCalculate:null//预约数据列表-用于计算单元格合并
      }
    },
    mounted() {
          let model = {
            interfaceVersion: 19000101,
          }

          this.$api.post('/saRegionList.sahtml', model, jsonObj => {
            this.regionList = jsonObj.regionList;
            if(this.regionList!=null && this.regionList.length>0)
            {
              this.regionSearchId = this.regionList[0].tableId;

              var now = new Date();
              var year = now.getFullYear(); //得到年份
              var month = now.getMonth()+1;//得到月份
              var date = now.getDate();//得到日期

              if(month < 10) month = "0"+month;
              if(date < 10) date = "0"+date;
              this.appointmentDate = year+""+month+""+date;

              this.getLaboratoryList();
            }
          },jsonObj => {
            this.$message.error(jsonObj.info);
          })
    },
    methods:{
      //获取预约数据
      getAppointmentList(){
        if(this.appointmentDate == null)
        {
            var now = new Date();
            var year = now.getFullYear(); //得到年份
            var month = now.getMonth()+1;//得到月份
            var date = now.getDate();//得到日期
            if(month<10) this.appointmentDate = year+"0"+month+""+date;
            else this.appointmentDate = year+""+month+""+date;

            this.$message.info("需要指定一个预约日期,默认当天");
        }

        if(this.regionSearchId == null)
        {
            if(this.regionList!=null && this.regionList.length>0)
            {
                this.regionSearchId = this.regionList[0].tableId;
            }
        }

        let model = {
          interfaceVersion: 19000101,
          regionId:this.regionSearchId,
          appointmentDate:this.appointmentDate,
          authState:23,//(审核通过且非取消)不是一个状态，仅用于查询
        }

        let loadingInstance = Loading.service({
            lock: true,
            spinner: 'el-icon-loading',
            // background: 'rgba(0, 0, 0, 0.7)'
        });

        this.$api.post('/saAppointmentList.sahtml', model, jsonObj => {
          this.appointmentListFromServer = jsonObj.appointmentList;
          this.recalculate();
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })

        loadingInstance.close();
      },
      //计算动态日历数据
      recalculate() {
          this.laboratoryListPublic = this.laboratoryListPublicTemp;//延迟更新实验室列表

          var i;
          var j;
          var tempLaboratoryList;
          var tempAppointmentList = [];

          //初始化appointmentListForShow数据：1、默认都是没有预约的
          for(i=0;i<this.timeLable.length-1;i++)
          {
              tempLaboratoryList = {};
              for(j=0;j<this.laboratoryListPublic.length;j++)
              {//实验室列表
                  tempLaboratoryList["lab"+this.laboratoryListPublic[j].tableId]={};
              }
              tempAppointmentList[i] = {
                startTimeStr:this.timeLable[i],
                endTimeStr:this.timeLable[i+1],
                laboratoryList:tempLaboratoryList
              }
          }

          //初始化appointmentListForShow数据：2、填充有预约的数据
          var appointment;
          var tempAppointment;
          for(i=0;i<this.appointmentListFromServer.length;i++)
          {
              appointment = this.appointmentListFromServer[i];

              tempAppointment = {
                projectName:appointment.projectName,
                projectId:appointment.projectId,
                theDescription:appointment.theDescription,
                companyName:appointment.companyName,
                customerId:appointment.customerId,
                customerName:appointment.customerName,
                customerPhoneNumber:appointment.customerPhoneNumber,
                laboratoryName:appointment.laboratoryName,
                //2020-07-23 新增
                marketerId:appointment.marketerId,
                marketerName:appointment.marketerName,
                marketerPhoneNumber:appointment.marketerPhoneNumber
              };

              // console.log(appointment.startTimeStr+"有值"+appointment.laboratoryName)

              tempAppointmentList[this.timeIndex[appointment.startTimeStr]].laboratoryList["lab"+appointment.laboratoryId] = tempAppointment;
          }

          // console.log(tempAppointmentList)

          //初始化appointmentListForShow数据：3、合并同一个“客户”连续时间段的预约数据
          this.appointmentListForCalculate=new Array();
          for(j=0;j<this.timeLable.length-1;j++)//this.timeLable.length=49
          {
              this.appointmentListForCalculate[j]=new Array();
          }
          //console.log(this.laboratoryListPublic.length,this.timeLable.length)
          for(i=0;i<this.laboratoryListPublic.length;i++)
          {
              for(j=0;j<this.timeLable.length-1;)//[0,47]  j<48
              {
                  this.appointmentListForCalculate[j][i] = {rowspan:1,colspan:1}
                  // this.appointmentListForCalculate[j][i].backgroundColor = this.backgroundColor[1];

                  var k = j + 1;

                  if(k<this.timeLable.length-1)//[0,47]  k<48
                  {
                      var customerIdTop = tempAppointmentList[j].laboratoryList["lab"+this.laboratoryListPublic[i].tableId].customerId;
                      var projectIdTop = tempAppointmentList[j].laboratoryList["lab" + this.laboratoryListPublic[i].tableId].projectId;
                      var theDescriptionTop = tempAppointmentList[j].laboratoryList["lab" + this.laboratoryListPublic[i].tableId].theDescription;
                      var customerIdButton = tempAppointmentList[k].laboratoryList["lab"+this.laboratoryListPublic[i].tableId].customerId;
                      var projectIdButton = tempAppointmentList[k].laboratoryList["lab" + this.laboratoryListPublic[i].tableId].projectId;
                      var theDescriptionButton = tempAppointmentList[k].laboratoryList["lab" + this.laboratoryListPublic[i].tableId].theDescription;

                      if(customerIdTop)
                      {
                          this.appointmentListForCalculate[j][i].backgroundColor = this.backgroundColor[customerIdTop%this.backgroundColor.length];

                          if(customerIdButton)
                          {
                              while(theDescriptionTop == theDescriptionButton && projectIdTop == projectIdButton && customerIdTop == customerIdButton && k < this.timeLable.length - 2)//[0,46]  k<47
                              {
                                  this.appointmentListForCalculate[j][i].rowspan++;//合并行数（同一个客户的多个连续时间段合并为一个，列不需要合并）
                                  // console.log(customerIdTop%5,this.backgroundColor[customerIdTop%5])

                                  this.appointmentListForCalculate[k][i] = {rowspan:0,colspan:0};

                                  k++;
                                  customerIdButton = tempAppointmentList[k].laboratoryList["lab"+this.laboratoryListPublic[i].tableId].customerId;
                                  projectIdButton = tempAppointmentList[k].laboratoryList["lab" + this.laboratoryListPublic[i].tableId].projectId;
                                  theDescriptionButton = tempAppointmentList[k].laboratoryList["lab" + this.laboratoryListPublic[i].tableId].theDescription;
                              }
                          }
                      }
                  }

                  j=k;
              }
          }

          // console.log(this.appointmentListForCalculate)

          this.appointmentListForShow = tempAppointmentList;

          this.$nextTick(function(){
            this.$refs.multipleTable.doLayout();
          })
      },
      //单元格合并规则设置
      arraySpanMethod({ row, column, rowIndex, columnIndex }) {
          if(this.appointmentListForCalculate && columnIndex > 0)
          {
              // console.log(rowIndex, columnIndex-1)
              var temp = this.appointmentListForCalculate[rowIndex][columnIndex-1];
              return [temp.rowspan,temp.colspan];
          }
          else
          {
              return [1,1]
          }
      },
      //单元格样式规则设置
      cellStyle({ row, column, rowIndex, columnIndex }){
          if(columnIndex == 0)
          {
            return 'background-color: #f3f7f6!important;'
          }
          else if(this.appointmentListForCalculate)
          {
              var temp = this.appointmentListForCalculate[rowIndex][columnIndex-1];
              if(temp.backgroundColor)
              {
                  return "background-color:"+temp.backgroundColor+"!important;vertical-align:top;";
              }
          }
      },
      //获取实验室列表
      getLaboratoryList() {
          let model = {
            interfaceVersion: 19000101,
            regionId:this.regionSearchId,
          }

          this.$api.post('/saLaboratoryList.sahtml', model, jsonObj => {
            this.laboratoryListPublicTemp = jsonObj.laboratoryList;

            this.getAppointmentList();
          },jsonObj => {
            this.$message.error(jsonObj.info);
          })
      },
      //获取地区列表
      getRegionForSelectList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saRegionList.sahtml', model, jsonObj => {
            // console.log(jsonObj.regionList)
          this.regionList = jsonObj.regionList;
          if(this.regionList!=null && this.regionList.length>0)
          {
            this.regionSearchId = this.regionList[0].tableId;
          }
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      headStyle({row, rowIndex}){
        if(rowIndex == 0){
          return 'background-color: #e8f8ed;color: #666;'
        }
      },
    }
  }
</script>
<style scoped lang="less">
  .cs-mb-10 {
    margin-bottom: 10px;
  }
  .info-company {
    min-width: 200px;
    background: #fff;
    border-radius: 4px;
    padding: 15px 10px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    .title {
      float: left;
      display: block;
    }
    .info {
      padding-left: 90px;
      display: block;
    }
  }
  .company-wrap{
    .td-name {
      cursor: pointer;
      color: #fff;
    }
    &:hover .td-name {
      color: #0e85ff;
    }
  }
</style>
<style>
  .overView .el-table__body tr.hover-row.el-table__row--striped > td, .el-table__body tr.hover-row > td, .el-table--enable-row-hover .el-table__body tr:hover > td{
    background-color: transparent!important;
  }
</style>
