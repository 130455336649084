<template>
  <div class="cs-content">
    <div class="cs-title">分公司<div class="cs-btn btn-add" @click="addBranch">新建分公司</div></div>
    <div class="cs-table">
      <el-table :data="branchList" stripe style="width: 100%;" height="calc(100vh - 232px)">
        <el-table-column type="index" label="序号">
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="regionName" label="所属地区"></el-table-column>
        <el-table-column prop="theName" label="分公司"></el-table-column>
        <el-table-column label="操作">
          <template scope="scope">
            <div class="td-btns text-success" @click="editBranch(scope.row)">编辑</div>
            <div class="line">|</div>
            <div class="td-btns text-danger" @click="delBranch(scope.row.tableId)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15">
        <span class="dialog-title">所属地区：</span>
        <div class="dialog-input">
          <el-select v-model="regionId" filterable placeholder="请选择所属地区" size="small">
            <el-option
                v-for="item in regionList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">分公司：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入分公司名称" size="small" v-model="theName"></el-input>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "branchList",
    components: {TipDialog},
    data() {
      return {
        branchList: [],

        theName: "",
        regionId: "",
        regionList: [],

        handleTitle:'删除',
        handleInfo:'您确定要删除该公司吗？',

        cuDialogShow:false,
        cuDialogTitle: "",//新建编辑弹窗标题
        cuDialogType: "",//新建编辑弹窗类型 用于区分是新建还是编辑 create,update

        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage: 0,

        idArr: []
      }
    },
    mounted() {
      this.getRegionForSelectList();
      this.getBranchList();
    },
    methods:{
      cancel() {
        this.$refs.topDialog.hide();
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.regionId = "";
        this.theName = "";
        this.idArr = [];
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saBranchAdd.sahtml' : '/saBranchUpdate.sahtml';
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          regionId: this.regionId,
          theName: this.theName,
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getBranchList();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      addBranch() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建分公司";
        this.cuDialogType = "create";
      },
      editBranch(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "编辑分公司";
        this.cuDialogType = "update";
        this.tableId = obj.tableId;
        this.regionId = obj.regionId;
        this.theName = obj.theName;
      },
      getBranchList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
        }

        this.$api.post('/saBranchList.sahtml', model, jsonObj => {
          console.log("分公司列表");
          console.log(jsonObj);
          this.branchList = jsonObj.branchList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getRegionForSelectList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saRegionList.sahtml', model, jsonObj => {
          console.log("地区列表");
          console.log(jsonObj);
          this.regionList = jsonObj.regionList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getBranchList();
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saBranchDelete.sahtml', model, jsonObj => {
          this.cancel();
          this.getBranchList();
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      delBranch(tableId){
        this.$refs.topDialog.show();
        this.idArr.push(tableId);
      }
    }
  }
</script>
<style scoped lang="less">
  .btn-add{
    float: right;
    margin-top: -5px;
  }
</style>
