<template>
    <el-dialog :title="title" :visible.sync="showDialog" :show-close=false width="400px">
        <span class="dialog-tip">{{info}}</span>
        <div slot="footer" class="dialog-footer">
            <div class="cs-btn btn-cancel" @click="hide">取消</div>
            <div class="cs-btn btn-sure" @click="onSureClick">确定</div>
        </div>
    </el-dialog>
</template>

<script>
    import Vue from 'vue'
    import {
        Dialog
    } from 'element-ui';

    Vue.use(Dialog);

    export default {
        name: "TopTipDialog",
        data() {
            return {
                showDialog: false
            }
        },
        props: {
            title: String,
            info: String,
            onSureClick: {
                type: Function
            }

        },
        methods: {
            hide() {
                this.showDialog = false
                // this.$emit('onCloseDialog', false)
            },
            show() {
                this.showDialog = true
            }
        }
    }
</script>

<style scoped>

</style>
