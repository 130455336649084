<template>
  <div>
    <div class="cs-head">
      <div class="head-logo">
        <img src="../assets/images/top_logo.png" />
      </div>
      <div class="head-user">
        <span>你好，管理员：{{loginAdminName}}</span>
        <div class="btn-exit" @click="exit">
          <img src="../assets/images/top_exit.png" />
          <span>安全退出</span>
        </div>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Dialog} from 'element-ui';
  Vue.use(Dialog)

  import TipDialog from "../components/TipDialog";

  export default {
    name:"headTop",
    components: {TipDialog},
    data() {
      return {
        handleTitle:'退出',
        handleInfo:'您确定要退出吗？',

        loginAdminName: localStorage.getItem("loginAdminName"),
      }
    },

    methods : {
      sureSave(){
        let model = {
          interfaceVersion: 19000101
        }

        this.$api.post('/saAdminLoginOut.sahtml', model, jsonObj => {
          console.log("登出");
          console.log(jsonObj);
          this.$message.success(jsonObj.info);
          localStorage.setItem("loginAdminToken","");
          localStorage.setItem("loginAdminName","");
          localStorage.setItem("loginAdminId","");
          location.href="login.html#/"
        },jsonObj => {
          console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      exit(){
        this.$refs.topDialog.show()
      }
    },
  }
</script>
<style scoped lang="less">
  @import "../assets/css/common.less";
  .cs-head{
    background: #fff;
    width: 100%;
    height: @headH;
    line-height: @headH;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 30;
    box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.1);
  }
  .head-logo{
    padding-left: 25px;
    img{
      height: 44px;
      margin-top: -2px;
    }
  }
  .head-user{
    position: absolute;
    right: 25px;
    top: 0;
    cursor: pointer;
    .btn-exit{
      display: #display[i];
      border: 1px solid #colors[primary];
      font-size: @fontSize - 2px;
      color: #colors[primary];
      line-height: 32px;
      border-radius: 4px;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 20px;
      img{
        width: 14px;
        margin-right: 7px;
      }
      span{
        vertical-align: #vertical[m];
      }
    }
  }
</style>