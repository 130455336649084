<template>
  <div class="cs-nav">
    <ul class="nav-scroll">
      <router-link to="/" v-if="adminMenuUrlList.indexOf('index') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_dashboard.png" class="nav-img">
              <img src=".././assets/images/side_ico_dashboard_sel.png" class="nav-imgSel">
              <span>数据统计</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/appointmentOverview" v-if="adminMenuUrlList.indexOf('appointmentOverview') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_service_overview.png" class="nav-img">
              <img src=".././assets/images/side_ico_service_overview_sel.png" class="nav-imgSel">
              <span>服务预约概览</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/appointmentList" v-if="adminMenuUrlList.indexOf('appointmentList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_service.png" class="nav-img">
              <img src=".././assets/images/side_ico_service_sel.png" class="nav-imgSel">
              <span>服务预约</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/appointmentListNoNew" v-if="adminMenuUrlList.indexOf('appointmentListNoNew') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_service.png" class="nav-img">
              <img src=".././assets/images/side_ico_service_sel.png" class="nav-imgSel">
              <span>服务预约审核</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/newsList" v-if="adminMenuUrlList.indexOf('newsList') > -1">
        <li class="nav-border" :class="{'router-link-exact-active' : activeFlag == '/newsList'}">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_news.png" class="nav-img">
              <img src=".././assets/images/side_ico_news_sel.png" class="nav-imgSel">
              <span>新闻资讯</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/messageList" v-if="adminMenuUrlList.indexOf('messageList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_message.png" class="nav-img">
              <img src=".././assets/images/side_ico_message_sel.png" class="nav-imgSel">
              <span>消息记录</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/workDayList" v-if="adminMenuUrlList.indexOf('workDayList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_workday.png" class="nav-img">
              <img src=".././assets/images/side_ico_workday_sel.png" class="nav-imgSel">
              <span>工作日设置</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/courseScheduleList" v-if="adminMenuUrlList.indexOf('courseScheduleList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_project.png" class="nav-img">
              <img src=".././assets/images/side_ico_project_sel.png" class="nav-imgSel">
              <span>项目开放状态</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/adminList" v-if="adminMenuUrlList.indexOf('adminList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_admin.png" class="nav-img">
              <img src=".././assets/images/side_ico_admin_sel.png" class="nav-imgSel">
              <span>管理员账号</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/marketerList" v-if="adminMenuUrlList.indexOf('marketerList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_sale.png" class="nav-img">
              <img src=".././assets/images/side_ico_sale_sel.png" class="nav-imgSel">
              <span>用户</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/customerList" v-if="adminMenuUrlList.indexOf('customerList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_customer.png" class="nav-img">
              <img src=".././assets/images/side_ico_customer_sel.png" class="nav-imgSel">
              <span>客户信息</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/linkManList" v-if="adminMenuUrlList.indexOf('linkManList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_pelple.png" class="nav-img">
              <img src=".././assets/images/side_ico_pelple_sel.png" class="nav-imgSel">
              <span>联系人信息</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/regionList" v-if="adminMenuUrlList.indexOf('regionList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_region.png" class="nav-img">
              <img src=".././assets/images/side_ico_region_sel.png" class="nav-imgSel">
              <span>地区管理</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/branchList" v-if="adminMenuUrlList.indexOf('branchList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_company.png" class="nav-img">
              <img src=".././assets/images/side_ico_company_sel.png" class="nav-imgSel">
              <span>分公司</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/laboratoryList" v-if="adminMenuUrlList.indexOf('laboratoryList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_room.png" class="nav-img">
              <img src=".././assets/images/side_ico_room_sel.png" class="nav-imgSel">
              <span>实验室</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/projectList" v-if="adminMenuUrlList.indexOf('projectList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_test.png" class="nav-img">
              <img src=".././assets/images/side_ico_test_sel.png" class="nav-imgSel">
              <span>实验项目</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/projectLaboratoryRelationList" v-if="adminMenuUrlList.indexOf('projectLaboratoryRelationList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_relation.png" class="nav-img">
              <img src=".././assets/images/side_ico_relation_sel.png" class="nav-imgSel">
              <span>项目实验室关系表</span>
            </a>
          </div>
        </li>
      </router-link>
      <router-link to="/skeyList" v-if="adminMenuUrlList.indexOf('skeyList') > -1">
        <li class="nav-border">
          <div class="parent-wrap">
            <a class="nav-parent">
              <img src=".././assets/images/side_ico_key.png" class="nav-img">
              <img src=".././assets/images/side_ico_key_sel.png" class="nav-imgSel">
              <span>Skey管理</span>
            </a>
          </div>
        </li>
      </router-link>
    </ul>
  </div>
</template>
<script>
  export default {
    name:"sidebar",
    data() {
      return {
        activeFlag:null,

        //管理员当前拥有权限的菜单
        adminMenuUrlList:[],
      }
    },
    mounted(){
      this.activeFlag = null
      if(this.$route.query && this.$route.query.enterPageUrl)
      {
        this.activeFlag = this.$route.query.enterPageUrl
      }

      this.getAdminMenuList()
    },
    methods : {
      //获取管理员拥有的菜单权限列表
      getAdminMenuList() {
        let model = {
          interfaceVersion: 19000101,
        }
        this.$api.post('/saAdminMenuUrlList.sahtml', model, jsonObj => {
          //当前用户的权限数据
          this.adminMenuUrlList = jsonObj.adminMenuUrlList ? jsonObj.adminMenuUrlList : []

          /**
           * 1、当访问的页面没有权限时，自动跳转到权限中的第一个页面
           * 2、没有任何权限的用户，直接跳转到登录页面
           */
          let nowRoutePath = this.$route.path//获取当前url中的路由，例如：/appointmentOverview

          //================== 在左侧菜单中点不到的页面，需要做特殊处理 Start ===================//
          if (nowRoutePath === '/')
          {
            nowRoutePath = '/index'
          }
          else if(nowRoutePath === '/newsAdd')
          {
            nowRoutePath = '/newsList'
          }
          else if(nowRoutePath === '/newsUpdate')
          {
            nowRoutePath = '/newsList'
          }
          //================== 在左侧菜单中点不到的页面，需要做特殊处理 End ===================//
          nowRoutePath = nowRoutePath.replace("/", "")
          // console.info(nowRoutePath)

          if(this.adminMenuUrlList == null || this.adminMenuUrlList.length == 0) {
            window.location.href="login.html#/"//没有任何权限的用户，直接跳转到登录页面
          }
          else if (this.adminMenuUrlList.indexOf(nowRoutePath) == -1){
            let jumpUrl = (this.adminMenuUrlList && this.adminMenuUrlList.length > 0) ? this.adminMenuUrlList[0] : "/"
            if(jumpUrl === 'index') jumpUrl = "/"
            window.location.href="index.html#/"+jumpUrl
          }

          // console.info(this.$route.path)
          //console.info(this.adminMenuUrlList)
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
    },
    watch : {
      '$route.query.enterPageUrl': function(){
        this.activeFlag = this.$route.query.enterPageUrl
        console.log(this.$route.query.enterPageUrl, this.activeFlag)
      }
    }
  }
</script>

<style scoped lang="less">
  @import "../assets/css/common.less";
  .cs-nav{
    background-color: #fff;
    display: #display[i];
    width: @narWidth;
    height: 100%;
    padding-top: @navPT;
    transition: all 0.3s;
    vertical-align: #vertical[t];
    overflow-x: hidden;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    top: @headH;
    z-index: 25;
    box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.1);
  }
  .nav-scroll{
    height: calc(100% - (@headH + @navPT));
    overflow-y: auto;
    overflow-x: hidden;
    width: @narWidth + 17px;
    box-sizing: border-box;
  }
  .nav-parent{
    width: @narWidth;
    display: block;
    text-decoration: none;
    color: #colors[defaultText];
    padding: 15px 0;
    cursor: pointer;
    box-sizing: border-box;
    border-left: 2px solid transparent;
  }
  .nav-parent{
    img{
      width: 18px;
      margin: 0 25px 0 40px;
    }
    span{
      vertical-align: #vertical[m];
    }
    .nav-imgSel{
      display: #display[n];
    }
  }
  .router-link-exact-active{
    .nav-parent{
      background-color: fade(#colors[primary], 10%);
      color: #colors[primary];
      border-left: 2px solid #colors[primary];
      .nav-img{
        display: #display[n];
      }
      .nav-imgSel{
        display: #display[i];
      }
    }
  }
</style>
