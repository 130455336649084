import GlobalData from '../common/GlobalData';
const baseUrl = GlobalData.API_SERVER_BASE_URL;
import {
    Message,
} from 'element-ui';
import plupload from 'plupload'

export default {
    upload
}

function upload(inputUpload, onSuccess) {
    let uploader = new plupload.Uploader({
        runtimes: 'html5,flash,silverlight,html4',
        browse_button: inputUpload,
        //multi_selection: false,
        // container: document.getElementById('container'),
        url: 'http://oss.aliyuncs.com',
        filters: {
            mime_types: [ //只允许上传图片和zip文件
                {title: "Image files", extensions: "jpg,jpeg,gif,png,bmp"},
                {title: "Zip files", extensions: "zip,rar"},
                {title: "Apk files", extensions: "apk"}
            ],
            // max_file_size: '10mb', //最大只能上传10mb的文件
            prevent_duplicates: false, //true不允许选取重复文件，false允许选取重复文件
        },
        init: {
            FilesAdded: function (up, files) {
                //console.log("FilesAdded")
                plupload.each(files, function (file) {
                    setUploadParam(up, '', false);
                });
            },
            BeforeUpload: function (up, file) {
                //console.log("BeforeUpload")
                setUploadParam(up, file.name, true);
            },
            FileUploaded: function (up, file, info) {
                //console.log("FileUploaded")
                if (info.status == 200 || info.status == 203) {
                    //203是上传回调服务器失败，失败原因
                    let fileUrl = host + g_object_name;
                    //console.info(fileUrl);
                    file.url=fileUrl
                    onSuccess(fileUrl,file)
                    //将上传成功的图片封装在Vue实例中
                    // _this.fileData.push({
                    //     "fileText": file.name,
                    //     "fileName": file.name,
                    //     "filePath": host + g_object_name
                    // });
                    //将子组件的数据传给父组件
                    // _this.$emit('getFileInfoJsonArrStr', _this.fileData)
                } else {
                    Message.error(info.response);
                }
            },
            Error: function (up, err) {
                //console.log("Error")
                if (err.code == -600) {
                    Message.error("选择的文件太大了,可以根据应用情况，在upload.js 设置一下上传的最大大小");
                } else if (err.code == -601) {
                    Message.error("选择的文件后缀不对,可以根据应用情况，在upload.js进行设置可允许的上传文件类型");
                } else if (err.code == -602) {
                    Message.error("这个文件已经上传过一遍了");
                } else {
                    Message.error(err.response);
                }
            }
        }
    });
    uploader.init();
}


//================== 阿里云OSS前端上传 Start =============//
let accessid = ''
let accesskey = ''
let host = ''
let policyBase64 = ''
let signature = ''
let filename = ''
let key = ''
let expire = 0
let g_object_name = ''
let now = Date.parse(new Date()) / 1000;

function send_request() {
    var xmlhttp = null;
    if (window.XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    }

    if (xmlhttp != null) {
        // serverUrl是 用户获取 '签名和Policy' 等信息的应用服务器的URL，请将下面的IP和Port配置为您自己的真实信息。
        let serverUrl = GlobalData.API_SERVER_BASE_URL + '/GetPolicy.html'

        xmlhttp.open("GET", serverUrl, false);
        xmlhttp.send(null);

        // console.info(xmlhttp.responseText)
        return xmlhttp.responseText
    } else {
        // noty({"text": "您的浏览器不支持XML HTTP请求，请换Chrome浏览器", "type": "error", "timeout": 4000});
        setTimeout(function () {
            alert("您的浏览器不支持XML HTTP请求，请换Chrome浏览器");
        }, 4000);
    }
}

function get_signature() {
    //console.log("update get_signature")
    //可以判断当前expire是否超过了当前时间，如果超过了当前时间，就重新取一下，3s作为缓冲。
    let now = Date.parse(new Date()) / 1000;
    if (expire < now + 3) {
        let body = send_request()
        //console.info(body)

        var obj = eval("(" + body + ")");
        host = obj['host']
        policyBase64 = obj['policy']
        accessid = obj['accessid']
        signature = obj['signature']
        expire = parseInt(obj['expire'])
        // callbackbody = obj['callback']
        key = obj['dir']
        return true;
    }
    return false;
}

function random_string(len) {
    //console.log("update random_string")

    len = len || 32;
    var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    var maxPos = chars.length;
    var pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

function get_suffix(filename) {
    let pos = filename.lastIndexOf('.')
    let suffix = ''
    if (pos != -1) {
        suffix = filename.substring(pos)
    }
    return suffix;
}

function calculate_object_name(filename) {
    let date = new Date();
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    month = month < 10 ? '0' + month : month
    let day = date.getDate()
    day = day < 10 ? ('0' + day) : day

    let suffix = get_suffix(filename)
    g_object_name = key + year + "/" + month + "/" + day + "/" + random_string(20) + suffix
    return ''
}

function setUploadParam(up, filename, ret) {
    if (ret == false) {
        ret = get_signature()
    }
    g_object_name = key;
    if (filename != '') {
        let suffix = get_suffix(filename)
        calculate_object_name(filename)
    }
    let new_multipart_params = {
        'key': g_object_name,
        'policy': policyBase64,
        'OSSAccessKeyId': accessid,
        'success_action_status': '200', //让服务端返回200,不然，默认会返回204
        'signature': signature,
    };

    up.setOption({
        'url': host,
        'multipart_params': new_multipart_params
    });

    up.start();
}
