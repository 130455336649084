import { render, staticRenderFns } from "./AppointmentListNoNew.vue?vue&type=template&id=5d04fd99&scoped=true"
import script from "./AppointmentListNoNew.vue?vue&type=script&lang=js"
export * from "./AppointmentListNoNew.vue?vue&type=script&lang=js"
import style0 from "./AppointmentListNoNew.vue?vue&type=style&index=0&id=5d04fd99&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d04fd99",
  null
  
)

export default component.exports