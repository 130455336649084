<template>
	<div class="cs-content">
    <h3 class="cs-title">客户信息</h3>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="公司名称/用户姓名/手机号/PM姓名/手机号" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-btn btn-search" @click="getCustomerList">搜索</div>
      <div class="cs-btn btn-add" @click="addCustomer">新建客户</div>
    </div>
    <div class="cs-table">
      <el-table :data="customerList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center" fixed>
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column label="公司信息" min-width="80" fixed>
          <template scope="scope">
            <div>公司：{{scope.row.companyName}}</div>
            <div>电话：{{scope.row.companyTel}}</div>
            <div>地址：{{scope.row.companyAddress}}</div>
            <div>初次合作：{{scope.row.cooperationTimeStampStr ? scope.row.cooperationTimeStampStr.substring(0,10) : ""}}</div>
          </template>
        </el-table-column>
        <!--<el-table-column label="联系人信息" width="470">
          <template scope="scope">
            <div class="contacts">联系人1</div>
            <div class="user-text">
              <span>姓名：{{scope.row.theName}}</span>
              <span>部门：{{scope.row.department}}</span>
              <span>职位：{{scope.row.position}}</span>
            </div>
            <div class="user-text">
              <span>电话：{{scope.row.phoneNumber}}</span>
              <span>邮箱：{{scope.row.email}}</span>
            </div>
            <div class="contacts-other" v-if="scope.row.theNameOther != null || scope.row.phoneNumberOther != null || scope.row.emailOther != null || scope.row.departmentOther != null || scope.row.positionOther != null">
              <div class="contacts">联系人2</div>
              <div class="user-text">
                <span v-if="scope.row.theNameOther != null">姓名：{{scope.row.theNameOther}}</span>
                <span v-if="scope.row.departmentOther != null">部门：{{scope.row.departmentOther}}</span>
                <span v-if="scope.row.positionOther != null">职位：{{scope.row.positionOther}}</span>
              </div>
              <div class="user-text">
                <span v-if="scope.row.phoneNumberOther != null">电话：{{scope.row.phoneNumberOther}}</span>
                <span v-if="scope.row.emailOther != null">邮箱：{{scope.row.emailOther}}</span>
              </div>
            </div>
          </template>
        </el-table-column>-->
        <el-table-column prop="laboratory" label="客户属性/产品类" width="200">
          <template scope="scope">
            <div>客户属性：{{scope.row.customerAttribute}}</div>
            <div>产品类：{{scope.row.productType}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="marketerName" label="用户" min-width="80">
          <template scope="scope">
            <div>姓名：{{scope.row.marketerName}}</div>
            <div>手机：{{scope.row.marketerPhoneNumber}}</div>
            <div>邮箱：{{scope.row.marketerEmail}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="marketerPMName" label="PM" min-width="80">
          <template scope="scope">
            <div>姓名：{{scope.row.marketerPMName}}</div>
            <div>手机：{{scope.row.marketerPMPhoneNumber}}</div>
            <div>邮箱：{{scope.row.marketerPMEmail}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="theRemark" label="备注" min-width="100"></el-table-column>
        <el-table-column label="操作" width="140">
          <template scope="scope">
            <div class="td-btns text-success" @click="editCustomer(scope.row)">编辑</div>
            <div class="line">|</div>
            <div class="td-btns text-danger" @click="delCustomer(scope.row.tableId)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="860px">
      <div class="dialog-width">
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">用户：</span>
          <div class="dialog-input">
            <el-select v-model="marketerId" filterable placeholder="请选择用户" size="small">
              <el-option
                      v-for="item in marketerList"
                      :key="item.tableId"
                      :label="item.theName"
                      :value="item.tableId">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">公司：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入客户公司名称" size="small" v-model="companyName"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">客户属性：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入客户属性" size="small" v-model="customerAttribute"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">地址：</span>
          <div class="dialog-input">
            <el-input type="textarea" :autosize="{ minRows: 7}" placeholder="请输入客户公司地址" size="small" v-model="companyAddress"></el-input>
          </div>
        </div>
      </div>
      <div class="dialog-width">
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">PM：</span>
          <div class="dialog-input">
            <el-select v-model="marketerPMId" filterable placeholder="请选择PM" size="small">
              <el-option
                      v-for="item in marketerList"
                      :key="item.tableId"
                      :label="item.theName"
                      :value="item.tableId">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">公司电话：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入客户公司电话" size="small" v-model="companyTel"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">产品类：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入产品类名称" size="small" v-model="productType"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">初始合作：</span>
          <div class="dialog-input">
            <el-date-picker
                    v-model="cooperationTimeStampStr"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="small"
                    placeholder="选择初始合作日期">
            </el-date-picker>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="dialog-title">备注：</span>
          <div class="dialog-input">
            <el-input type="textarea" :autosize="{ minRows: 7}" placeholder="请输入备注" v-model="theRemark"></el-input>
          </div>
        </div>
      </div>
      <!--<div class="dialog-width">
        <div class="user-title">联系人1</div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">联系人姓名：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人姓名" size="small" v-model="theName"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">联系人部门：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人部门" size="small" v-model="department"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">联系人职位：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人职位" size="small" v-model="position"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">联系人电话：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人电话" size="small" v-model="phoneNumber"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">联系人邮箱：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人邮箱" size="small" v-model="email"></el-input>
          </div>
        </div>
        <div class="user-title">联系人2</div>
        <div class="cs-mb-15">
          <span class="dialog-title">联系人姓名：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人姓名" size="small" v-model="theNameOther"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="dialog-title">联系人部门：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人部门" size="small" v-model="departmentOther"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="dialog-title">联系人职位：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人职位" size="small" v-model="positionOther"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="dialog-title">联系人电话：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人电话" size="small" v-model="phoneNumberOther"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="dialog-title">联系人邮箱：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人邮箱" size="small" v-model="emailOther"></el-input>
          </div>
        </div>
      </div>-->
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
	</div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,Select,Option} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Select)
  Vue.use(Option)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "customerList",
    components: {TipDialog},
    data() {
      return {
        keyword:'',
        customerList: [],
        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage:0,

        cuDialogShow: false,
        cuDialogTitle: "",
        cuDialogType: "",
        tableId: null,
        marketerId: "",
        marketerPMId: "",
        marketerList: [],
        companyName: "",
        companyTel: "",
        customerAttribute: "",
        productType: "",
        companyAddress: "",
        cooperationTimeStampStr: "",

        //联系人1
        theName: "",
        department: "",
        position: "",
        phoneNumber: "",
        email: "",

        //联系人2
        theNameOther: "",
        departmentOther: "",
        positionOther: "",
        phoneNumberOther: "",
        emailOther: "",

        theRemark: "",

        handleTitle:'删除',
        handleInfo:'您确定要删除该客户吗？',

        idArr: []
      }
    },
    mounted() {
      this.getMarketerList();
      this.getCustomerList();
    },
    methods:{
      getMarketerList() {
        let model = {
          interfaceVersion: 19000101,
          loginFlag: 1,
          appointFlag: 1,
        }

        this.$api.post('/saMarketerList.sahtml', model, jsonObj => {
          console.log("用户列表");
          console.log(jsonObj);
          this.marketerList = jsonObj.marketerList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getCustomerList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
        }

        this.$api.post('/saCustomerList.sahtml', model, jsonObj => {
          console.log("客户列表");
          console.log(jsonObj);
          this.customerList = jsonObj.customerList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      addCustomer() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建客户";
        this.cuDialogType = "create";
      },
      editCustomer(obj) {
        //=================== 被删除的用户，在编辑时回显出来 Start ========================//
        if (obj.marketerState == 3 || obj.marketerPMState == 3) {
          if (obj.marketerId && obj.marketerPMId && obj.marketerId != obj.marketerPMId) {
            if (obj.marketerState == 3) {
              this.marketerList.push({
                "tableId":obj.marketerId,
                "theName":obj.marketerName+"（已删除）"
              })
            }
            else if (obj.marketerPMState == 3) {
              this.marketerList.push({
                "tableId":obj.marketerPMId,
                "theName":obj.marketerPMName+"（已删除）"
              })
            }
          }
          else if (obj.marketerId && obj.marketerPMId && obj.marketerId === obj.marketerPMId) {
              this.marketerList.push({
                "tableId": obj.marketerId,
                "theName": obj.marketerName + "（已删除）"
              })
          }
        }
        //=================== 被删除的用户，在编辑时回显出来 End ========================//

        this.cuDialogShow = true;
        this.cuDialogTitle = "编辑客户";
        this.cuDialogType = "update";
        this.tableId = obj.tableId;
        this.marketerId = obj.marketerId;
        this.marketerPMId = obj.marketerPMId;
        this.companyName = obj.companyName;
        this.companyTel = obj.companyTel;
        this.customerAttribute = obj.customerAttribute;
        this.productType = obj.productType;
        this.companyAddress = obj.companyAddress;
        this.cooperationTimeStampStr = obj.cooperationTimeStampStr ? obj.cooperationTimeStampStr.substring(0,10) : "";
        this.theName = obj.theName;
        this.department = obj.department;
        this.position = obj.position;
        this.phoneNumber = obj.phoneNumber;
        this.email = obj.email;
        this.theNameOther = obj.theNameOther,
        this.departmentOther = obj.departmentOther,
        this.positionOther = obj.positionOther,
        this.phoneNumberOther = obj.phoneNumberOther,
        this.emailOther = obj.emailOther,
        this.theRemark = obj.theRemark;
      },
      cancel() {
        this.$refs.topDialog.hide();
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.marketerId = "";
        this.marketerPMId = "";
        this.companyName = "";
        this.companyTel = "";
        this.customerAttribute = "";
        this.productType = "";
        this.companyAddress = "";
        this.cooperationTimeStampStr = "";
        this.theName = "";
        this.department = "";
        this.position = "";
        this.phoneNumber = "";
        this.email = "";
        this.theNameOther = "";
        this.departmentOther = "";
        this.positionOther = "";
        this.phoneNumberOther = "";
        this.emailOther = "";
        this.theRemark = "";
        this.idArr = [];
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saCustomerAdd.sahtml' : '/saCustomerUpdate.sahtml';
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          marketerId: this.marketerId,
          marketerPMId: this.marketerPMId,
          companyName: this.companyName,
          companyTel: this.companyTel,
          customerAttribute: this.customerAttribute,
          productType: this.productType,
          companyAddress: this.companyAddress,
          cooperationTimeStampStr: this.cooperationTimeStampStr,
          theName: this.theName,
          department: this.department,
          position: this.position,
          phoneNumber: this.phoneNumber,
          email: this.email,
          theNameOther: this.theNameOther,
          departmentOther: this.departmentOther,
          positionOther: this.positionOther,
          phoneNumberOther: this.phoneNumberOther,
          emailOther: this.emailOther,
          theRemark: this.theRemark,
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getCustomerList();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getCustomerList();
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saCustomerDelete.sahtml', model, jsonObj => {
          this.cancel();
          this.getCustomerList();
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      delCustomer(tableId){
        this.$refs.topDialog.show()
        this.idArr.push(tableId);
      }
    }
  }
</script>
<style scoped lang="less">
  .btn-add{
    float: right;
  }
  .dialog-width{
    display: inline-block;
    width: 390px;
    padding: 0 10px;
    vertical-align: top;
  }
  .user-title{
    background-color: rgba(44, 147, 65, 0.1);
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    color: #2c9341;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .cs-mb-15{
    position: relative;
    .star{
      position: absolute;
      left: -9px;
      top: 8px;
      color: red;
    }
  }
  .user-text span{
    display: inline-block;
    margin-right: 10px;
    min-width: 135px;
  }
  .contacts{
    color: #2c9341;
    font-weight: 600;
  }
  .contacts-other{
    margin-top: 10px;
  }
</style>
