<template>
  <div class="cs-content">
    <h3 class="cs-title">项目实验室关系</h3>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="实验项目/实验室" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-sel">
        <div class="cs-ml sel-width">
          <el-select v-model="regionId" clearable filterable placeholder="请选择地区" size="small" @change="getBranchForSelectList">
            <el-option
                v-for="item in regionList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
        <div class="cs-ml sel-width">
          <el-select v-model="branchId" clearable filterable placeholder="请选择分公司" size="small" @change="getLaboratoryForSelectList">
            <el-option
                v-for="item in branchList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
        <div class="sel-width">
          <el-select v-model="laboratoryId" clearable filterable placeholder="请选择实验室" size="small">
            <el-option
                v-for="item in laboratoryList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-btn btn-search" @click="getProjectAndLaboratoryRelationList">搜索</div>
    </div>
    <div class="cs-table">
      <el-table :data="projectAndLaboratoryRelationList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="regionName" label="所属地区"></el-table-column>
        <el-table-column prop="branchName" label="所属分公司"></el-table-column>
        <el-table-column prop="laboratoryName" label="实验室"></el-table-column>
        <el-table-column prop="projectName" label="实验项目"></el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,Select,Option} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Select)
  Vue.use(Option)

  export default {
    name: "projectLaboratoryRelationList",
    data() {
      return {
        keyword: '',
        regionId: '',
        regionList: [],
        branchId: '',
        branchList: [],
        laboratoryId: '',
        laboratoryList: [],

        projectAndLaboratoryRelationList: [],

        pageNumber: 1,
        countPerPage: 20,
        totalCount: 0,
        totalPage: 0,

      }
    },
    mounted() {
      this.getRegionForSelectList();
      this.getProjectAndLaboratoryRelationList();
    },
    methods:{
      getRegionForSelectList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saRegionList.sahtml', model, jsonObj => {
          console.log("地区列表");
          console.log(jsonObj);
          this.regionList = jsonObj.regionList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getBranchForSelectList() {
        this.branchId = "";
        this.laboratoryId = "";
        let model = {
          interfaceVersion: 19000101,
          regionId: this.regionId,
        }

        this.$api.post('/saBranchList.sahtml', model, jsonObj => {
          console.log("分公司列表");
          console.log(jsonObj);
          this.branchList = jsonObj.branchList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getLaboratoryForSelectList() {
        this.laboratoryId = "";
        let model = {
          interfaceVersion: 19000101,
          branchId: this.branchId
        }

        this.$api.post('/saLaboratoryList.sahtml', model, jsonObj => {
          console.log("实验室列表");
          console.log(jsonObj);
          this.laboratoryList = jsonObj.laboratoryList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getProjectAndLaboratoryRelationList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
          regionId: this.regionId,
          branchId: this.branchId,
          laboratoryId: this.laboratoryId
        }

        this.$api.post('/saProjectAndLaboratoryRelationList.sahtml', model, jsonObj => {
          console.log("实验室列表");
          console.log(jsonObj);
          this.projectAndLaboratoryRelationList = jsonObj.projectAndLaboratoryRelationList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getProjectAndLaboratoryRelationList();
      },
    }
  }
</script>
<style scoped lang="less">
  .btn-add{
    float: right;
  }
  .cs-input{
    width: 200px;
  }
</style>
