<template>
	<div class="cs-content">
    <div class="handle-btns">
      <div class="cs-btn btn-add" @click="addAppointment">新建服务预约</div>
      <div class="cs-btn btn-add"  @click="pass(null)" v-if="idArr.length > 0 && authState == 24">通过</div>
      <div class="cs-btn btn-danger"  @click="editAppointment(null)" v-if="idArr.length > 0 && authState == 24">驳回</div>
      <div class="cs-btn btn-add" @click="completeAppointment(null)" v-if="idArr.length > 0 && authState == 23">完成</div>
      <div class="cs-btn btn-danger" @click="cancelAppointment(null)" v-if="idArr.length > 0 && authState == 23">取消</div>
    </div>
    <h3 class="cs-title">服务预约</h3>
    <div class="tab-wrap">
      <ul class="tab-state">
        <li :class="{'active':authState == 24}" @click="chooseState(24)">待审核（{{waitCount}}）</li>
        <li :class="{'active':authState == 23}" @click="chooseState(23)">审核通过（{{passCount}}）</li>
        <li :class="{'active':authState == 22}" @click="chooseState(22)">审核驳回/取消（{{rejectCount}}）</li>
      </ul>
    </div>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="客户/负责人/用户/联系人" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-sel">
        <div class="cs-ml sel-width">
          <el-select v-model="regionSearchId" clearable filterable placeholder="请选择地区" size="small" @change="getBranchSearchList">
            <el-option
                    v-for="item in regionSearchList"
                    :key="item.tableId"
                    :label="item.theName"
                    :value="item.tableId">
            </el-option>
          </el-select>
        </div>
        <div class="cs-ml sel-width">
          <el-select v-model="branchSearchId" clearable filterable placeholder="请选择分公司" size="small" @change="getLaboratorySearchList">
            <el-option
                    v-for="item in branchSearchList"
                    :key="item.tableId"
                    :label="item.theName"
                    :value="item.tableId">
            </el-option>
          </el-select>
        </div>
        <div class="cs-ml sel-width">
          <el-select v-model="laboratorySearchId" clearable filterable placeholder="请选择实验室" size="small">
            <el-option
                    v-for="item in laboratorySearchList"
                    :key="item.tableId"
                    :label="item.theName"
                    :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-date">
        <span class="text">预约时间：</span>
        <el-date-picker size="small" value-format="yyyy-MM-dd HH:mm:ss" v-model="searchDate" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </div>
      <div class="cs-btn btn-search" @click="getAppointmentList">搜索</div>
    </div>
    <div class="cs-table">
      <el-table :data="appointmentList" stripe style="width: 100%" height="calc(100vh - 342px)" ref="multipleTable" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="30" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="45" align="center" fixed>
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column label="客户" width="240" fixed>
          <template scope="scope">
            <div>公司名：{{scope.row.companyName}}</div>
            <div>公司电话：{{scope.row.companyTel}}</div>
            <div>客户属性：{{scope.row.customerAttribute}}</div>
          </template>
        </el-table-column>
        <!--<el-table-column prop="leading" label="负责人" width="200">
          <template scope="scope">
            <div>姓名：{{scope.row.customerName}}</div>
            <div>部门：{{scope.row.department}}</div>
            <div>职位：{{scope.row.position}}</div>
            <div>电话：{{scope.row.customerPhoneNumber}}</div>
            <div>邮箱：{{scope.row.customerEmail}}</div>
          </template>
        </el-table-column>-->
        <el-table-column prop="sale" label="用户" width="230">
          <template scope="scope">
            <div>姓名：{{scope.row.marketerName}}</div>
            <div>手机：{{scope.row.marketerPhoneNumber}}</div>
            <div>邮箱：{{scope.row.marketerEmail}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="sale" label="联系人" width="230">
          <template scope="scope">
            <div>姓名：{{scope.row.linkManName}}</div>
            <div>部门：{{scope.row.linkManDepartment}}</div>
            <div>职位：{{scope.row.linkManPosition}}</div>
            <div>手机：{{scope.row.linkManPhoneNumber}}</div>
            <div>邮箱：{{scope.row.linkManEmail}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="laboratoryName" label="实验室" width="130"></el-table-column>
        <el-table-column prop="projectName" label="实验项目" width="130"></el-table-column>
        <el-table-column label="日期" width="160">
          <template scope="scope">
            <div>开始：{{scope.row.appointmentDateStr}}</div>
            <div>结束：{{scope.row.appointmentEndDateStr}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="时间段" width="120">
          <template scope="scope">
            <div>开始：{{scope.row.startTimeStr}}</div>
            <div>结束：{{scope.row.endTimeStr}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="theDescription" label="用途说明" min-width="180"></el-table-column>
        <el-table-column label="是否完成" width="90" v-if="authState == 23">
          <template scope="scope">
            <div v-if="scope.row.isComplete == 1" class="cs-btn btn-open">是</div>
            <div v-if="scope.row.isComplete == 2" class="cs-btn btn-close">否</div>
          </template>
        </el-table-column>
        <el-table-column v-if="authState != 22" label="操作" width="170">
          <template scope="scope">
            <div v-if="authState == 24" class="td-btns text-success" @click="pass(scope.row.tableId)">通过</div>
            <div v-if="authState == 24" class="line">|</div>
            <div v-if="authState == 24" class="td-btns text-danger" @click="editAppointment(scope.row)">驳回</div>
            <div v-if="authState == 23 && scope.row.isComplete == 2" class="td-btns text-danger" @click="cancelAppointment(scope.row)">取消</div>
            <div v-if="authState == 23 && scope.row.isComplete == 2" class="line">|</div>
            <div v-if="authState == 23 && scope.row.isComplete == 2" class="td-btns text-success" @click="completeAppointment(scope.row.tableId)">标记为已完成</div>
          </template>
        </el-table-column>
        <el-table-column v-if="authState == 22" label="原因" width="150">
          <template scope="scope">
            <div class="text-danger" v-if="scope.row.authState == 3">驳回：{{scope.row.rejectReason}}</div>
            <div class="text-danger" v-if="scope.row.theState == 2">取消：{{scope.row.cancelReason}}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">客户：</span>
        <div class="dialog-input">
          <el-select v-model="customerId" filterable placeholder="请选择客户" size="small" @change="getMarketerName">
            <el-option
                v-for="item in customerList"
                :key="item.tableId"
                :label="item.companyName"
                :value="item.tableId">
            </el-option>
          </el-select>
          <div class="text-tip" v-if="marketerName.length > 0">关联用户：{{marketerName}}</div>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">联系人：</span>
        <div class="dialog-input">
          <el-select v-model="linkManId" filterable placeholder="请选择联系人" size="small">
            <el-option
                    v-for="item in linkManList"
                    :key="item.tableId"
                    :label="item.theName+' '+item.phoneNumber+'['+item.department+']'"
                    :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">分公司：</span>
        <div class="dialog-input">
          <el-select v-model="branchId" filterable placeholder="请选择分公司" size="small" @change="branchChange">
            <el-option
                    v-for="item in branchList"
                    :key="item.tableId"
                    :label="item.theName"
                    :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">实验室：</span>
        <div class="dialog-input">
          <el-select v-model="laboratoryId" filterable placeholder="请选择实验室" size="small" @change="laboratoryChange">
            <el-option
                    v-for="item in laboratoryList"
                    :key="item.tableId"
                    :label="item.theName"
                    :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">实验项目：</span>
        <div class="dialog-input">
          <el-select v-model="projectId" filterable placeholder="请选择实验项目" size="small">
            <el-option
                v-for="item in projectList"
                :key="item.tableId"
                :label="item.theName"
                :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">预约日期：</span>
        <div class="dialog-input">
          <el-date-picker
              v-model="appointDate"
              type="daterange"
              value-format="yyyy-MM-dd"
              size="small"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">时间段：</span>
        <div class="dialog-input">
          <div class="time">
            <el-time-select placeholder="起始时间" size="small" v-model="appointTimeMinStr" :picker-options="{start: '00:00',step: '00:30',end: '24:00'}"></el-time-select>
          </div>
          <div class="mark">~</div>
          <div class="time">
            <el-time-select placeholder="结束时间" size="small" v-model="appointTimeMaxStr" :picker-options="{start: '00:00',step: '00:30',end: '24:00',minTime: appointTimeMinStr}"></el-time-select>
          </div>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">用途说明：</span>
        <div class="dialog-input">
          <el-input type="textarea" :autosize="{ minRows: 3}" placeholder="请输入用途说明" v-model="theDescription"></el-input>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'update'">
        <span class="dialog-title">驳回原因：</span>
        <div class="dialog-input">
          <el-input type="textarea" :autosize="{ minRows: 3}" placeholder="请输入驳回原因" v-model="rejectReason"></el-input>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'cancel'">
        <span class="dialog-title">取消原因：</span>
        <div class="dialog-input">
          <el-input type="textarea" :autosize="{ minRows: 3}" placeholder="请输入取消原因" v-model="cancelReason"></el-input>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
	</div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,DatePicker,TimeSelect,Button,Select,Option} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(DatePicker)
  Vue.use(TimeSelect)
  Vue.use(Button)
  Vue.use(Select)
  Vue.use(Option)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "appointmentList",
    components: {TipDialog},
    data() {
      return {
        waitCount: null,
        passCount: null,
        rejectCount: null,
        authState: 24,
        keyword:'',
        regionSearchId:'',
        regionSearchList: [],
        branchSearchId:'',
        branchSearchList: [],
        laboratorySearchId:'',
        laboratorySearchList: [],
        searchDate: '',

        appointmentList: [],
        pageNumber: 1,
        countPerPage: 100,
        totalCount: 0,
        totalPage: 0,

        cuDialogShow:false,
        cuDialogTitle: "",
        cuDialogType: "",

        customerId:'',
        linkManId:'',
        customerList: [],
        linkManList: [],
        projectId:'',
        projectList: [],
        laboratoryId:'',
        laboratoryList: [],
        branchId:'',
        branchList:[],
        appointDate:'',
        appointTimeMinStr: '',
        appointTimeMaxStr:'',
        theDescription:'',
        rejectReason:'',
        cancelReason:'',

        handleType:'pass',//'complete'
        handleTitle:'通过',
        handleInfo:'您确定要审核通过该预约吗？',

        marketerName: "",

        idArr: []
      }
    },
    mounted(){
      this.getRegionSearchList();
      this.getAppointmentList();
      this.getCustomerList();
      this.getBranchList();
    },
    methods:{
      handleSelectionChange(val) {
        this.idArr = [];
        if  (val != null && val.length > 0) {
          for (var i=0;i<val.length;i++) {
            this.idArr.push(val[i].tableId);
          }
        }
      },
      completeAppointment(tableId) {
        this.handleType = 'complete';
        this.handleTitle = '完成';
        this.handleInfo = '您确定要完成该预约吗？';
        this.$refs.topDialog.show();
        if(tableId != null) {
          this.idArr.push(tableId);
        }
      },
      getBranchList(){
        this.branchId = "";
        let model = {
          interfaceVersion: 19000101,
        }
        this.$api.post('/saBranchForSelectList.sahtml', model, jsonObj => {
          // console.log("实验室列表");
          // console.log(jsonObj);
          this.branchList = jsonObj.branchList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      branchChange(){
        this.projectId = ""
        this.projectList = []
        this.laboratoryId = ""
        this.laboratoryList = []

        if(this.branchId && this.branchId > 0)
        {
          this.getLaboratoryList()
        }
      },
      laboratoryChange(){
        this.projectId = ""
        this.projectList = []

        if(this.laboratoryId && this.laboratoryId > 0)
        {
          this.getProjectList()
        }
      },
      getLaboratoryList() {
        this.laboratoryId = "";
        let model = {
          interfaceVersion: 19000101,
          branchId: this.branchId
        }
        this.$api.post('/saLaboratoryList.sahtml', model, jsonObj => {
          // console.log("实验室列表");
          // console.log(jsonObj);
          this.laboratoryList = jsonObj.laboratoryList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getMarketerName() {
        for(var i=0;i<this.customerList.length;i++) {
          if(this.customerList[i].tableId == this.customerId) {
            this.marketerName = this.customerList[i].marketerName;
            break;
          }
        }

        //加载联系人列表信息
        if(this.customerId == null || this.customerId < 1)
        {
            this.linkManId = ""
            this.linkManList = []
        }
        else
        {
          this.linkManId = ""
          this.linkManList = []
          this.getLinkManList()
        }
      },
      getProjectList() {
        let model = {
          interfaceVersion: 19000101,
          laboratoryId : this.laboratoryId,
        }

        this.$api.post('/saProjectForSelectList.sahtml', model, jsonObj => {
          // console.log("项目列表");
          // console.log(jsonObj);
          this.projectList = jsonObj.projectList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getLinkManList() {
        let model = {
          interfaceVersion: 19000101,
          customerId: this.customerId,
        }

        this.$api.post('/saLinkManForSelectList.sahtml', model, jsonObj => {
          // console.log("客户列表");
          // console.log(jsonObj);
          this.linkManList = jsonObj.linkManList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getCustomerList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saCustomerList.sahtml', model, jsonObj => {
          // console.log("客户列表");
          // console.log(jsonObj);
          this.customerList = jsonObj.customerList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      chooseState(authState) {
        this.authState = authState;
        this.getAppointmentList();
        this.$nextTick(function(){
          this.$refs.multipleTable.doLayout();
        })
      },
      getRegionSearchList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saRegionList.sahtml', model, jsonObj => {
          // console.log("搜索地区列表");
          // console.log(jsonObj);
          this.regionSearchList = jsonObj.regionList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getBranchSearchList() {
        this.branchSearchId = "";
        this.laboratorySearchId = "";
        let model = {
          interfaceVersion: 19000101,
          regionId: this.regionSearchId,
        }

        this.$api.post('/saBranchList.sahtml', model, jsonObj => {
          // console.log("分公司列表");
          // console.log(jsonObj);
          this.branchSearchList = jsonObj.branchList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getLaboratorySearchList() {
        this.laboratorySearchId = "";
        let model = {
          interfaceVersion: 19000101,
          branchId: this.branchSearchId
        }

        this.$api.post('/saLaboratoryList.sahtml', model, jsonObj => {
          // console.log("实验室列表");
          // console.log(jsonObj);
          this.laboratorySearchList = jsonObj.laboratoryList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getAppointmentList() {
        let model = {
          interfaceVersion: 19000101,
          authState: this.authState,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
          regionId: this.regionSearchId,
          branchId: this.branchSearchId,
          laboratoryId: this.laboratorySearchId,
          searchBeginTimeStr: this.searchDate != null && this.searchDate[0] != undefined ? this.searchDate[0] : null,
          searchEndTimeStr:this.searchDate != null &&  this.searchDate[1] != undefined ? this.searchDate[1] :null
        }

        this.$api.post('/saAppointmentList.sahtml', model, jsonObj => {
          // this.$message.success(jsonObj.info);
          // console.log("预约列表");
          // console.log(jsonObj);
          this.appointmentList = jsonObj.appointmentList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
          this.waitCount = jsonObj.waitCount;
          this.passCount = jsonObj.passCount;
          this.rejectCount = jsonObj.rejectCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      addAppointment() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建预约";
        this.cuDialogType = "create";
      },
      editAppointment(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "审核驳回";
        this.cuDialogType = "update";
        if(obj != null && obj != undefined) {
          this.idArr.push(obj.tableId);
        }
      },
      cancelAppointment(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "取消预约";
        this.cuDialogType = "cancel";
        if(obj != null) {
          this.idArr.push(obj.tableId);
        }
      },
      cancel() {
        this.$refs.topDialog.hide();
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.customerId = "";
        this.linkManId = "";
        this.branchId = "";
        this.projectId = "";
        this.projectList = [];
        this.laboratoryId = "";
        this.laboratoryList = [];
        this.appointDate = "";
        this.appointTimeMinStr = "";
        this.appointTimeMaxStr = "";
        this.theDescription = "";
        this.rejectReason = "";
        this.cancelReason = "";
        this.marketerName = "";
        this.idArr = [];
        this.$refs.multipleTable.clearSelection();
      },
      sure() {
        let url = "";
        if (this.cuDialogType == 'create') {
          url = '/saAppointmentAdd.sahtml';
        } else if (this.cuDialogType == 'update') {
          url = '/saAppointmentAuthReject.sahtml';
        } else if (this.cuDialogType == 'cancel') {
          url = '/saAppointmentCancel.sahtml';
        }
        let model = {
          interfaceVersion: 19000101,
          customerId: this.customerId,
          linkManId: this.linkManId,
          projectId: this.projectId,
          laboratoryId: this.laboratoryId,
          appointDateMinStr: this.appointDate ? this.appointDate[0] : "",
          appointDateMaxStr: this.appointDate ? this.appointDate[1] : "",
          appointTimeMinStr: this.appointTimeMinStr,
          appointTimeMaxStr: this.appointTimeMaxStr,
          theDescription: this.theDescription,
          rejectReason: this.rejectReason,
          cancelReason: this.cancelReason,
          idArr: this.idArr
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          if (this.cuDialogType == 'create') {
            this.authState = 23;
          } else if (this.cuDialogType == 'update') {
            this.authState = 22;
          } else if (this.cuDialogType == 'cancel') {
            this.authState = 22;
          }
          this.getAppointmentList();
          this.$nextTick(function(){
            this.$refs.multipleTable.doLayout();
          })
          this.cancel();
          this.$refs.multipleTable.clearSelection();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      handleCurrentChange(val) {
        this.idArr = [];
	this.$refs.multipleTable.clearSelection();
        this.pageNumber = val;
        this.getAppointmentList();
      },
      sureSave(){
        let url = this.handleType == 'pass' ? '/saAppointmentAuthPass.sahtml' : '/saAppointmentComplete.sahtml';
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr
        }
        this.$api.post(url, model, jsonObj => {
          if (this.handleType == 'pass') this.handleComplete()
          this.cancel();
          this.getAppointmentList();
          this.$message.success(jsonObj.info);
          this.$refs.multipleTable.clearSelection();
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      handleComplete() {
        let url = '/saAppointmentComplete.sahtml';
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr
        }
        this.$api.post(url, model, jsonObj => {
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      pass(tableId){
        this.handleType = 'pass';
        this.handleTitle = '通过';
        this.handleInfo = '您确定要审核通过该预约吗？';
        this.$refs.topDialog.show();
        if(tableId != null) {
          this.idArr.push(tableId);
        }
      }
    }
  }
</script>
<style scoped lang="less">
	@import "../assets/css/common.less";
  .btn-open{
    padding: 1px 7px;
    border-radius: 2px;
    cursor: default;
    background-color: #colors[primary];
  }
  .cs-content{
    position: relative;
    .handle-btns{
      position: absolute;
      right: 25px;
      top: 40px;
    }
    .btn-add,.btn-danger{
      margin: 0 5px;
    }
  }
  .tab-wrap{
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;
    .tab-state{
      display: #display[i];
      li{
        display: #display[i];
        color: #colors[defaultText];
        padding: 0 10px 15px 10px;
        margin-right: 40px;
        cursor: pointer;
        position: relative;
      }
      .active{
        color: #colors[primary];
        border-bottom: 2px solid #colors[primary];
      }
    }
  }
  .dialog-input{
    .mark{
      width: 30px;
      display: #display[i];
      text-align: center;
    }
    .time{
      width: 150px;
      width: calc(50% - 15px);
      display: #display[i];
    }
    .text-tip{
      color: #colors[primary];
      margin-top: 10px;
    }
  }
</style>
