<template>
	<div class="cs-content">
    <h3 class="cs-title">联系人信息</h3>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="姓名/手机号/邮箱" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-sel">
        <div class="cs-ml sel-width">
          <el-select v-model="customerSearchId" clearable filterable placeholder="请选择公司" size="small" @change="departmentSearchId = null,getDepartmentSearchList()">
            <el-option
                    v-for="item in customerSearchList"
                    :key="item.tableId"
                    :label="item.companyName"
                    :value="item.tableId">
            </el-option>
          </el-select>
        </div>
        <div class="cs-ml sel-width">
          <el-select v-model="departmentSearchId" clearable filterable placeholder="请选择部门" size="small" @change="getDepartmentSearchList()">
            <el-option
                    v-for="item in departmentSearchList"
                    :key="item.tableId"
                    :label="item.theName"
                    :value="item.tableId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-btn btn-search" @click="getLinkManList">搜索</div>
      <div class="cs-btn btn-add" @click="add">新建联系人</div>
    </div>
    <div class="cs-table">
      <el-table :data="linkManList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center" fixed>
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column label="公司名称" min-width="180" fixed>
          <template scope="scope">
            <div>{{scope.row.companyName}}</div>
            <!--<div>电话：{{scope.row.companyTel}}</div>
            <div>地址：{{scope.row.companyAddress}}</div>
            <div>初次合作：{{scope.row.cooperationTimeStampStr ? scope.row.cooperationTimeStampStr.substring(0,10) : ""}}</div>-->
          </template>
        </el-table-column>
        <el-table-column label="部门" min-width="140">
          <template scope="scope">
            <span>{{scope.row.department}}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" min-width="100">
          <template scope="scope">
            <span>{{scope.row.theName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="职位" min-width="100">
          <template scope="scope">
            <span>{{scope.row.position}}</span>
          </template>
        </el-table-column>
        <el-table-column label="电话" min-width="100">
          <template scope="scope">
            <span>{{scope.row.phoneNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column label="邮箱" min-width="100">
          <template scope="scope">
            <span>{{scope.row.email}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template scope="scope">
            <div class="td-btns text-success" @click="edit(scope.row)">编辑</div>
            <div class="line">|</div>
            <div class="td-btns text-danger" @click="del(scope.row.tableId)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="430px">
      <div class="dialog-width">
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">关联客户：</span>
          <div class="dialog-input">
            <el-select v-model="customerId" filterable placeholder="请选择关联的客户" size="small" @change="customerChange">
              <el-option
                      v-for="item in customerList"
                      :key="item.tableId"
                      :label="item.companyName"
                      :value="item.tableId">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">联系人姓名：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人姓名" size="small" v-model="theName"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">联系人部门：</span>
          <div class="dialog-input">
            <el-select
                    v-model="department"
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入部门"
                    size="small">
              <el-option
                      v-for="item in departmentList"
                      :key="item.tableId"
                      :label="item.theName"
                      :value="item.tableId">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="cs-mb-15">
          <!--<span class="star">*</span>-->
          <span class="dialog-title">联系人职位：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人职位" size="small" v-model="position"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">联系人电话：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人电话" size="small" v-model="phoneNumber"></el-input>
          </div>
        </div>
        <div class="cs-mb-15">
          <span class="star">*</span>
          <span class="dialog-title">联系人邮箱：</span>
          <div class="dialog-input">
            <el-input placeholder="请输入联系人邮箱" size="small" v-model="email"></el-input>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
	</div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,Select,Option} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Select)
  Vue.use(Option)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "linkManList",
    components: {TipDialog},
    data() {
      return {
        keyword:'',
        linkManList: [],
        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage:0,

        customerSearchId:null,
        departmentSearchId:null,
        customerSearchList:[],
        departmentSearchList:[],

        cuDialogShow: false,
        cuDialogTitle: "",
        cuDialogType: "",
        tableId: null,

        //客户
        customerId: "",
        customerList: [],

        //联系人
        theName: "",
        department: "",
        position: "",
        phoneNumber: "",
        email: "",

        //部门
        departmentList:[],

        handleTitle:'删除',
        handleInfo:'您确定要删除该联系人吗？',

        idArr: []
      }
    },
    mounted() {
      this.getLinkManList();
      this.getCustomerList();
    },
    methods:{
      getCustomerList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saCustomerForSelectList.sahtml', model, jsonObj => {
          // console.log("客户列表");
          // console.log(jsonObj);
          this.customerList = jsonObj.customerList;
          this.customerSearchList = jsonObj.customerList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getDepartmentSearchList(){
        let model = {
          interfaceVersion: 19000101,
          customerId:this.customerSearchId,
        }

        this.$api.post('/saDepartmentForSelectList.sahtml', model, jsonObj => {
          console.log("部门列表");
          console.log(jsonObj);
          this.departmentSearchList = jsonObj.departmentList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      customerChange() {
        //加载部门列表信息
        if(this.customerId == null || this.customerId < 1)
        {
          this.department = ""
          this.departmentList = []
        }
        else
        {
          this.department = ""
          this.departmentList = []
          this.getDepartmentList()
        }
      },
      getLinkManList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
          departmentId: this.departmentSearchId,
          customerId: this.customerSearchId,
        }

        this.$api.post('/saLinkManList.sahtml', model, jsonObj => {
          console.log("联系人列表");
          console.log(jsonObj);
          this.linkManList = jsonObj.linkManList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getDepartmentList(thenFun) {
        let model = {
          interfaceVersion: 19000101,
          customerId: this.customerId,
        }

        this.$api.post('/saDepartmentForSelectList.sahtml', model, jsonObj => {
          console.log("部门列表");
          console.log(jsonObj);
          this.departmentList = jsonObj.departmentList;
          if(thenFun) thenFun()
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      add() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建联系人";
        this.cuDialogType = "create";
      },
      edit(obj) {
        let this_ = this
        this_.customerId = obj.customerId;
        let promise = new Promise((resolve, reject) => {
          this_.getDepartmentList(resolve)
        });
        promise.then(function(){
          this_.cuDialogShow = true;
          this_.cuDialogTitle = "编辑联系人";
          this_.cuDialogType = "update";
          this_.tableId = obj.tableId;
          this_.theName = obj.theName;
          this_.department = obj.department;
          this_.position = obj.position;
          this_.phoneNumber = obj.phoneNumber;
          this_.email = obj.email;
        });
      },
      cancel() {
        this.$refs.topDialog.hide();
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.customerId = "";
        this.theName = "";
        this.department = "";
        this.departmentList = [];
        this.position = "";
        this.phoneNumber = "";
        this.email = "";
        this.idArr = [];
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saLinkManAdd.sahtml' : '/saLinkManUpdate.sahtml';
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          customerId: this.customerId,
          theName: this.theName,
          department: this.department,
          position: this.position,
          phoneNumber: this.phoneNumber,
          email: this.email,
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getLinkManList();
        },jsonObj => {
          console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getLinkManList();
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saLinkManDelete.sahtml', model, jsonObj => {
          this.cancel();
          this.getLinkManList();
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      del(tableId){
        this.$refs.topDialog.show()
        this.idArr.push(tableId);
      }
    }
  }
</script>
<style scoped lang="less">
  .btn-add{
    float: right;
  }
  .dialog-width{
    display: inline-block;
    width: 390px;
    padding: 0 10px;
    vertical-align: top;
  }
  .user-title{
    background-color: rgba(44, 147, 65, 0.1);
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    color: #2c9341;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .cs-mb-15{
    position: relative;
    .star{
      position: absolute;
      left: -9px;
      top: 8px;
      color: red;
    }
  }
  .user-text span{
    display: inline-block;
    margin-right: 10px;
    min-width: 135px;
  }
  .contacts{
    color: #2c9341;
    font-weight: 600;
  }
  .contacts-other{
    margin-top: 10px;
  }
</style>
