<template>
  <div class="cs-content">
    <h3 class="cs-title">skey管理</h3>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="客户端类型/版本说明/skey" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-sel">
        <div class="cs-ml sel-width">
          <el-select v-model="platformSearch" clearable filterable placeholder="请选择平台" size="small">
            <el-option
                v-for="item in platformList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="cs-ml sel-width">
          <el-select v-model="environmentSearch" clearable filterable placeholder="请选择环境" size="small">
            <el-option
                v-for="item in environmentList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="sel-width">
          <el-select v-model="skeyStateSearch" clearable filterable placeholder="是否启用" size="small">
            <el-option
                v-for="item in skeyStateList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="cs-btn btn-search" @click="getSkeyUpdateInfoList">搜索</div>
      <div class="cs-btn btn-add" @click="addSkeyUpdateInfo">新建Skey</div>
    </div>
    <div class="cs-table">
      <el-table :data="skeyUpdateInfoList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="clientName" label="客户端类型" width="160"></el-table-column>
        <el-table-column prop="skey" label="Skey" width="280"></el-table-column>
        <el-table-column prop="apkMark" label="APK Mark" width="180"></el-table-column>
        <el-table-column prop="apkVersionNumber" label="APK版本号" width="140"></el-table-column>
        <el-table-column prop="apkRepositoryPath" label="APK链接" width="200"></el-table-column>
        <el-table-column prop="updateInfo" label="版本说明" min-width="160"></el-table-column>
        <el-table-column prop="generateDate" label="创建时间" width="160"></el-table-column>
        <el-table-column label="状态" width="100">
          <template scope="scope">
            <div v-if="scope.row.skeyState == 1" class="cs-btn btn-open">启用</div>
            <div v-if="scope.row.skeyState == 2" class="cs-btn btn-close">停用</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template scope="scope">
            <div class="td-btns text-success" @click="editLaboratory(scope.row)">编辑</div>
            <div class="line">|</div>
            <div v-if="scope.row.skeyState == 2" class="td-btns text-success" @click="updateState(scope.row)">启用</div>
            <div v-if="scope.row.skeyState == 1" class="td-btns text-danger" @click="updateState(scope.row)">停用</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title no-margin">平台：</span>
        <div class="dialog-input">
          <el-radio-group v-model="platform">
            <el-radio :label="1">安卓</el-radio>
            <el-radio :label="2">苹果</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title no-margin">环境：</span>
        <div class="dialog-input">
          <el-radio-group v-model="environment">
            <el-radio :label="1">生产</el-radio>
            <el-radio :label="2">测试</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">APK版本号：</span>
        <div class="dialog-input">
          <el-input type="text" placeholder="请输入APK版本号" size="small" v-model="apkVersionNumber"></el-input>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">版本说明：</span>
        <div class="dialog-input">
          <el-input type="textarea" :autosize="{ minRows: 3}" placeholder="请输入版本说明" v-model="updateInfo"></el-input>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title no-margin">状态：</span>
        <div class="dialog-input">
          <el-switch
              v-model="skeyState"
              active-color="#1fba40"
              inactive-color="#acacac"
              active-text="启用"
              inactive-text="不启用">
          </el-switch>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'update'">
        <span class="dialog-title">上传APK包：</span>
        <div class="dialog-input">
          <div class="file-wrap">
            <div class="cs-btn btn-sure">上传APK包</div>
            <input type="file" class="input-file" id="uploadApk"/>
          </div>
          <div class="text-tip">包名{{fileName}}</div>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,Select,Option,RadioGroup,Radio,Switch} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(RadioGroup)
  Vue.use(Radio)
  Vue.use(Switch)

  import TipDialog from ".././components/TipDialog";

  import fileUpload from '../assets/js/oss/FileUpload';

  export default {
    name: "skeyList",
    components: {TipDialog},
    data() {
      return {
        keyword:'',
        platformSearch:'',
        platformList: [{
          value: '1',
          label: '安卓'
        }, {
          value: '2',
          label: '苹果'
        }],
        environmentSearch:'',
        environmentList: [{
          value: '1',
          label: '生产'
        }, {
          value: '2',
          label: '测试'
        }],
        skeyStateSearch: "",
        skeyStateList: [{
          value: '1',
          label: '启用'
        }, {
          value: '2',
          label: '停用'
        }],
        skeyUpdateInfoList: [],
        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage:0,

        cuDialogShow: false,
        cuDialogTitle: "",
        cuDialogType: "",
        tableId: "",
        platform: "",
        environment: "",
        apkVersionNumber: "",
        updateInfo: "",
        skeyState: true,
        apkRepositoryPath: "",
        fileName: "",

        handleTitle: this.changeState == 1 ? '启用' : '停用',
        handleInfo: this.changeState == 1 ? '您确定要启用吗？' : '您确定要停用吗？',

        changeState: "",//将要改变成为的状态
      }
    },
    mounted() {
      this.getSkeyUpdateInfoList();
    },
    methods:{
      getSkeyUpdateInfoList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
          platform: this.platformSearch,
          environment: this.environmentSearch,
          skeyState: this.skeyStateSearch
        }

        this.$api.post('/saSkeyUpdateInfoList.sahtml', model, jsonObj => {
          console.log("版本更新列表");
          console.log(jsonObj);
          this.skeyUpdateInfoList = jsonObj.skeyUpdateInfoList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      addSkeyUpdateInfo() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建skey";
        this.cuDialogType = "create";
      },
      editLaboratory(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "编辑skey";
        this.cuDialogType = "update";
        this.tableId = obj.tableId;
        this.updateInfo = obj.updateInfo;
        var that = this;
        Vue.nextTick(function () {
          fileUpload.upload("uploadApk", (url,file) => {
            that.apkRepositoryPath = url;
            that.fileName = file.name;
          })
        })
      },
      cancel() {
        this.$refs.topDialog.hide();
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.platform = "";
        this.environment = "";
        this.apkVersionNumber = "";
        this.updateInfo = "";
        this.skeyState = "";
        this.apkRepositoryPath = "";
        this.fileName = "";
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saSkeyUpdateInfoAdd.sahtml' : '/saSkeyUpdateInfoUpdateAPK.sahtml';
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          platform: this.platform,
          environment: this.environment,
          apkVersionNumber: parseInt(this.apkVersionNumber),
          updateInfo: this.updateInfo,
          skeyState: this.skeyState ? 1 : 2,
          apkRepositoryPath: this.apkRepositoryPath,
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getSkeyUpdateInfoList();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getSkeyUpdateInfoList();
      },
      sureSave(){
        var that = this;
        let model = {
          interfaceVersion: 19000101,
          tableId: that.tableId,
          skeyState: that.changeState,
        }
        this.$api.post('/saSkeyUpdateInfoUpdateState.sahtml', model, jsonObj => {
          this.cancel();
          this.getSkeyUpdateInfoList();
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      updateState(obj){
        this.changeState = obj.skeyState == 1 ? 2 : 1;
        this.handleTitle = this.changeState == 1 ? '启用' : '停用'
        this.handleInfo = this.changeState == 1 ? '您确定要启用吗？' : '您确定要停用吗？'

        this.$refs.topDialog.show()
        this.tableId = obj.tableId;
      }
    }
  }
</script>
<style scoped lang="less">
  @import "../assets/css/common.less";
  .btn-add{
    float: right;
  }
  .cs-input{
    width: 200px;
  }
  .btn-open{
    padding: 1px 7px;
    border-radius: 2px;
    cursor: default;
    background-color: #colors[primary];
  }
  .no-margin{
    margin-top: 0;
  }
  .file-wrap {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    .input-file {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      font-size: 150px;
      opacity: 0;
    }
  }
  .text-tip{
    color: #666;
    margin-top: 10px;
  }
</style>
