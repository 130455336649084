import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引用API文件
import api from './assets/js/common/ServerInterface'

import {Message} from "element-ui";

import './assets/css/main.less'

import VueCookies from 'vue-cookies';

import VueResource from 'vue-resource'

// 将API方法绑定到全局
Vue.prototype.$api = api
Vue.prototype.$message = Message

Vue.config.productionTip = false
Vue.use(require('vue-wechat-title'))

//Cookie
Vue.use(VueCookies)

Vue.use(VueResource)

// 引用全局过滤器
import dfilters from "./assets/js/common/GlobalFilters.js"
// 将全局过滤器注册到当前页面的Vue中
Object.keys(dfilters).forEach(key => {
  // console.log(filters[key])
  Vue.filter(key, dfilters[key])
})

new Vue({
  router: router,//路由设置
  render: h => h(App),
}).$mount('#app')
