<template>
	<div class="cs-content">
    <h3 class="cs-title">用户</h3>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="用户名/手机号/邮箱" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-btn btn-search" @click="getMarketerList">搜索</div>
      <div class="cs-btn btn-add" @click="addMarketer">新建用户</div>
    </div>
    <div class="cs-table">
      <el-table :data="marketerList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center" fixed>
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="theName" label="用户名" width="140"></el-table-column>
        <el-table-column prop="phoneNumber" label="手机号" width="180"></el-table-column>
        <el-table-column prop="email" label="邮箱" width="230"></el-table-column>
        <el-table-column prop="createTimeStamp" label="账号开通时间" width="200"></el-table-column>
        <el-table-column prop="lastLoginDateTimeStamp" label="最后登录时间" width="200"></el-table-column>
        <el-table-column prop="loginFlag" label="登录权限" width="160">
          <template scope="scope">
            <el-switch
                v-model="scope.row.loginFlag"
                active-color="#1fba40"
                inactive-color="#acacac"
                @change="onChangePermission(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="appointFlag" label="预约权限" width="160">
          <template scope="scope">
            <el-switch
                v-model="scope.row.appointFlag"
                active-color="#1fba40"
                inactive-color="#acacac"
                @change="onChangePermission(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="240">
          <template scope="scope">
            <div class="td-btns text-success" @click="editMarketer(scope.row)">编辑</div>
            <div class="line">|</div>
            <div class="td-btns text-success" @click="resetPwdDialogShow = true,tableId=scope.row.tableId">重置密码</div>
            <div class="line">|</div>
            <div class="td-btns text-danger" @click="delUser(scope.row.tableId)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15">
        <span class="dialog-title">用户名：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入用户名" size="small" v-model="theName"></el-input>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">手机号：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入手机号" size="small" v-model="phoneNumber"></el-input>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">邮箱：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入邮箱" size="small" v-model="email"></el-input>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">登录密码：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入密码" size="small" v-model="loginPassWord"></el-input>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title switch">登录权限：</span>
        <div class="dialog-input">
          <el-switch
              v-model="loginFlag"
              active-color="#1fba40"
              inactive-color="#acacac"
              active-text="开"
              inactive-text="关">
          </el-switch>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title switch">预约权限：</span>
        <div class="dialog-input">
          <el-switch
              v-model="appointFlag"
              active-color="#1fba40"
              inactive-color="#acacac"
              active-text="开"
              inactive-text="关">
          </el-switch>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
    <el-dialog title="重置密码" :visible.sync="resetPwdDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15">
        <span class="dialog-title">登录密码：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入密码" size="small" v-model="loginPassWord"></el-input>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="changePassword">确定</div>
      </div>
    </el-dialog>
	</div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,Switch} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Switch)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "marketerList",
    components: {TipDialog},
    data() {
      return {
        marketerList: [],
        handleTitle:'删除',
        handleInfo:'您确定要删除该用户吗？',

        pageNumber: 1,
        countPerPage: 20,
        totalCount: 0,
        totalPage: 0,
        keyword: "",

        tableId: null,
        theName: "",
        phoneNumber: "",
        email: "",
        loginPassWord: "",
        loginFlag: null,
        appointFlag: null,

        cuDialogShow:false,//控制新建编辑弹框是否显示
        cuDialogTitle: "",//新建编辑弹窗标题
        cuDialogType: "",//新建编辑弹窗类型 用于区分是新建还是编辑 create,update

        resetPwdDialogShow: false,//控制重置密码弹窗是否显示

        idArr: []
      }
    },
    mounted() {
      this.getMarketerList();
    },
    methods:{
      changePassword() {
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          loginPassWord: this.loginPassWord,
        }
        this.$api.post('/saMarketerChangePassword.sahtml', model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      addMarketer() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建用户";
        this.cuDialogType = "create";
      },
      editMarketer(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "编辑用户";
        this.cuDialogType = "update";
        this.tableId = obj.tableId;
        this.theName = obj.theName;
        this.phoneNumber = obj.phoneNumber;
        this.email = obj.email;
        this.loginFlag = obj.loginFlag;
        this.appointFlag = obj.appointFlag;
      },
      cancel() {
        this.$refs.topDialog.hide();
        this.resetPwdDialogShow = false;
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.theName = "";
        this.phoneNumber = "";
        this.email = "";
        this.loginPassWord = "";
        this.loginFlag = null;
        this.appointFlag = null;
        this.idArr = [];
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saMarketerAdd.sahtml' : '/saMarketerUpdate.sahtml';
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          theName: this.theName,
          phoneNumber: this.phoneNumber,
          email: this.email,
          loginPassWord: this.loginPassWord,
          loginFlag: this.loginFlag ? 1 : 2,
          appointFlag: this.appointFlag ? 1 : 2
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getMarketerList();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      getMarketerList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
        }

        this.$api.post('/saMarketerList.sahtml', model, jsonObj => {
          console.log("用户列表");
          console.log(jsonObj);
          this.marketerList = jsonObj.marketerList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getMarketerList();
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saMarketerDelete.sahtml', model, jsonObj => {
          this.cancel();
          this.getMarketerList();
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      delUser(tableId){
        this.$refs.topDialog.show();
        this.idArr.push(tableId);
      },
      onChangePermission(item){
        console.log('item is ')
        console.log(item)

        let url = '/saMarketerUpdate.sahtml'
        let model = {
          ...item
        }
        model.interfaceVersion = 19000101
        model.loginFlag = item.loginFlag ? 1 : 2
        model.appointFlag = item.appointFlag ? 1 : 2
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          // this.cancel();
          this.getMarketerList();
        }, jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      }
    }
  }
</script>
<style scoped lang="less">
  .btn-add{
    float: right;
  }
  .dialog-title{
    &.switch{
      margin-top: 0;
    }
  }
</style>
