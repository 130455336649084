<template>
  <div v-wechat-title="$route.meta.title">
    <Head />
    <SideBar />
    <div class="cs-container">
        <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import Head from '@/components/Head.vue'
  import SideBar from '@/components/SideBar.vue'

  export default {
    name: "index",
    data() {
      return {}
    },
    components: {
      Head,
      SideBar
    }
  }
</script>

<style scoped>

</style>
