import Vue from 'vue'
import VueRouter from 'vue-router'

//引入页面
import index from "./index/index"
import appointmentList from "./appointmentList/AppointmentList"
import appointmentListNoNew from "./appointmentList/AppointmentListNoNew"
import appointmentOverview from "./appointmentOverview/AppointmentOverview"
import courseScheduleList from "./courseScheduleList/CourseScheduleList"
import workDayList from "./workDayList/WorkDayList"
import customerList from "./customerList/CustomerList"
import marketerList from "./marketerList/MarketerList"
import adminList from "./adminList/AdminList"
import regionList from "./regionList/RegionList"
import branchList from "./branchList/BranchList"
import laboratoryList from "./laboratoryList/LaboratoryList"
import projectList from "./projectList/ProjectList"
import projectLaboratoryRelationList from "./projectLaboratoryRelationList/ProjectLaboratoryRelationList"
import skeyList from "./skeyList/SkeyList"
import newsList from "./news/NewsList"
import newsAdd from "./news/NewsAdd"
import messageList from "./messageList/MessageList"
import linkManList from "./linkManList/LinkManList"

Vue.use(VueRouter)

export default new VueRouter({
	routes: [{
        path: '/',
        name: 'index',
        component: index,
        meta: {
          title: ['数据统计'],
        }
  },{
        path: '/appointmentList',
        name: 'appointmentList',
        component: appointmentList,
        meta:{
            title: ['服务预约'],
            keepalive:true
        }
    },{
        path: '/appointmentListNoNew',
        name: 'appointmentListNoNew',
        component: appointmentListNoNew,
        meta:{
            title: ['服务预约审核'],
            keepalive:true
        }
    },{
        path: '/appointmentOverview',
        name: 'appointmentOverview',
        component: appointmentOverview,
        meta:{
            title: ['服务预约概览'],
            keepalive:true
        }
    },{
        path: '/courseScheduleList',
        name: 'courseScheduleList',
        component: courseScheduleList,
        meta:{
            title: ['项目开放状态'],
            keepalive:true
        }
    },{
        path: '/workDayList',
        name: 'workDayList',
        component: workDayList,
        meta:{
            title: ['工作日设置'],
            keepalive:true
      }
    },{
        path: '/customerList',
        name: 'customerList',
        component: customerList,
        meta:{
            title: ['客户信息'],
            keepalive:true
      }
    },{
        path: '/linkManList',
        name: 'linkManList',
        component: linkManList,
        meta:{
            title: ['联系人信息'],
            keepalive:true
        }
    },{
        path: '/marketerList',
        name: 'marketerList',
        component: marketerList,
        meta:{
            title: ['用户'],
            keepalive:true
        }
    },{
        path: '/adminList',
        name: 'adminList',
        component: adminList,
        meta:{
            title: [`管理员账号`],
            keepalive:true
        }
    },{
        path: '/regionList',
        name: 'regionList',
        component: regionList,
        meta:{
            title: ['地区管理'],
            keepalive:true
        }
	},{
        path: '/branchList',
        name: 'branchList',
        component: branchList,
        meta:{
            title: ['分公司'],
            keepalive:true
        }
  },{
        path: '/laboratoryList',
        name: 'laboratoryList',
        component: laboratoryList,
        meta:{
            title: ['实验室'],
            keepalive:true
        }
  },{
        path: '/projectList',
        name: 'projectList',
        component: projectList,
        meta:{
            title: ['实验项目'],
            keepalive:true
        }
  },{
        path: '/projectLaboratoryRelationList',
        name: 'projectLaboratoryRelationList',
        component: projectLaboratoryRelationList,
        meta:{
            title: ['项目实验室关系表'],
            keepalive:true
        }
    },{
        path: '/skeyList',
        name: 'skeyList',
        component: skeyList,
        meta:{
            title: ['skey管理'],
            keepalive:true
        }
  },{
        path: '/newsList',
        name: 'newsList',
        component: newsList,
        meta:{
            title: ['新闻资讯'],
            keepalive:true
        }
  },{
        path: '/newsAdd',
        name: 'newsAdd',
        component: newsAdd,
        meta:{
            title: ['新增/编辑资讯'],
            keepalive:true
        }
  },{
        path: '/messageList',
        name: 'messageList',
        component: messageList,
        meta:{
            title: ['消息记录'],
            keepalive:true
        }
  }]
})
