/**
 * 在该文件中定义常量
 */
//服务器接口根地址（开发版：开发本地部署时用）
//const API_SERVER_BASE_URL = "http://127.0.0.1"

//Demo服务器
// const API_SERVER_BASE_URL = "https://mrtapitest.chisalsoft.co"

//客户正式服务器
const API_SERVER_BASE_URL = "https://mrtapp.mrt-cert.com"

export default {
	API_SERVER_BASE_URL
}
