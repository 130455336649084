<template>
	<div class="cs-content">
    <h3 class="cs-title">新闻资讯</h3>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="请输入标题/资讯来源" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-btn btn-search" @click="getNewsList">搜索</div>
      <router-link target="_blank" :to="{path:'/newsAdd',query:{ enterPageUrl:'/newsList' }}" class="cs-btn btn-add">新建资讯</router-link>
    </div>
    <div class="cs-table">
      <el-table :data="newsList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column label="封面" width="160">
          <template scope="scope">
            <el-image v-if="scope.row.theCoverImage != null && scope.row.theCoverImage.length > 0"
                style="width: 100px; height: 60px"
                :src="scope.row.theCoverImage"
                fit="cover">
            </el-image>
            <div v-else style="width: 100px; height: 60px;" class="el-image__error">无封面</div>
          </template>
        </el-table-column>
        <el-table-column prop="theTitle" label="标题" min-width="300"></el-table-column>
        <el-table-column prop="theSourceContent" label="资讯来源" width="200"></el-table-column>
        <el-table-column prop="publishInfo" label="发布信息" width="200"></el-table-column>
        <el-table-column prop="createTimeStampStr" label="创建时间" width="200"></el-table-column>
        <el-table-column label="操作" width="180">
          <template scope="scope">
            <div class="td-btns text-success"><router-link target="_blank" :to="{path:'/newsAdd',query:{ enterPageUrl:'/newsList' , tableId:scope.row.tableId }}">编辑</router-link></div>
            <div class="line">|</div>
            <div class="td-btns text-danger" @click="delNews(scope.row.tableId)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
	</div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Image} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Image)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "newsList",
    components: {TipDialog},
    data() {
      return {
        keyword:'',

        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage: 0,
        newsList:[],

        handleTitle:'删除',
        handleInfo:'您确定要删除吗？',

        idArr: [],
      }
    },
    mounted() {
      this.getNewsList();
    },
    methods:{
      getNewsList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
        }

        this.$api.post('/saNewsList.sahtml', model, jsonObj => {
          console.log("新闻列表");
          console.log(jsonObj);
          this.newsList = jsonObj.newsList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saNewsDelete.sahtml', model, jsonObj => {
          this.getNewsList();
          this.idArr = [];
          this.$refs.topDialog.hide()
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      handleCurrentChange(val){
        this.pageNumber = val;
        this.getNewsList();
      },
      delNews(tableId){
        this.$refs.topDialog.show()
        this.idArr.push(tableId);
      }
    }
  }
</script>
<style scoped lang="less">
  .btn-add{
    float: right;
  }
  .text-success{
    a{
      color: #2c9341;
    }
  }
</style>
