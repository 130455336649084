<template>
	<div class="cs-content">
    <h3 class="cs-title">管理员账号</h3>
    <div class="cs-search">
      <div class="cs-input">
        <el-input placeholder="用户名" prefix-icon="el-icon-search" v-model="keyword" size="small"></el-input>
      </div>
      <div class="cs-btn btn-search" @click="getAdminList">搜索</div>
      <div class="cs-btn btn-add" @click="addAdmin">新建管理员</div>
    </div>
    <div class="cs-table">
      <el-table :data="adminList" stripe style="width: 100%" height="calc(100vh - 282px)">
        <el-table-column type="index" label="序号" width="70" align="center">
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="theName" label="用户名" min-width="100"></el-table-column>
        <el-table-column prop="adminAndMenuRelationList" label="用户权限" min-width="280">
          <template scope="scope">
              {{scope.row.adminAndMenuRelationList | jsonArrayJoin("menu.theName",", ","string")}}
          </template>
        </el-table-column>
        <el-table-column prop="createTimeStamp" label="账号开通时间" min-width="180"></el-table-column>
        <el-table-column prop="lastLoginDateTimeStamp" label="最后登录时间" min-width="180"></el-table-column>
        <el-table-column label="操作" min-width="200">
          <template scope="scope">
            <div class="td-btns text-success" @click="editAdmin(scope.row)">编辑</div>
            <div class="line">|</div>
            <div class="td-btns text-success" @click="resetPwdDialogShow = true,tableId=scope.row.tableId">重置密码</div>
            <div class="line">|</div>
            <div class="td-btns text-success" @click="permissionDialogShow = true,tableId=scope.row.tableId,getAdminMenuList()">权限设置</div>
            <div class="line">|</div>
            <div class="td-btns text-danger" @click="delUser(scope.row.tableId)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15">
        <span class="dialog-title">用户名：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入用户名" size="small" v-model="theName"></el-input>
        </div>
      </div>
      <div class="cs-mb-15" v-if="cuDialogType == 'create'">
        <span class="dialog-title">登录密码：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入密码" size="small" v-model="loginPassWord"></el-input>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
    <el-dialog title="重置密码" :visible.sync="resetPwdDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15">
        <span class="dialog-title">登录密码：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入密码" size="small" v-model="loginPassWord"></el-input>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="changePassword">确定</div>
      </div>
    </el-dialog>
    <el-dialog title="权限设置" :visible.sync="permissionDialogShow" :show-close=false width="480px">
      <div class="menu-list" v-for="menu in menuList" :key="menu.tableId">
        <el-checkbox-group v-model="menuCheckList">
          <el-checkbox :label="menu.tableId" :value="menu.tableId">{{menu.theName}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancelPermission">取消</div>
        <div class="cs-btn btn-sure" @click="savePermission">确定</div>
      </div>
    </el-dialog>
	</div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog,CheckboxGroup,Checkbox} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(CheckboxGroup)
  Vue.use(Checkbox)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "adminList",
    components: {TipDialog},
    data() {
      return {
        handleTitle:'删除',
        handleInfo:'您确定要删除该管理员吗？',

        adminList: [],
        keyword:'',
        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage: 0,

        tableId: null,
        theName: "",
        loginPassWord: "",

        cuDialogShow: false,//控制新建编辑弹框是否显示
        cuDialogTitle: "",//新建编辑弹窗标题
        cuDialogType: "",//新建编辑弹窗类型 用于区分是新建还是编辑 create,update

        resetPwdDialogShow: false,//控制重置密码弹窗是否显示

        permissionDialogShow: false,//控制权限设置弹窗是否显示

        idArr: [],

        //菜单权限
        menuList:[],
        menuCheckList:[],
      }
    },
    mounted() {
      this.getAdminList();
      this.getMenuList();
    },
    methods:{
      changePassword() {
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          loginPassWord: this.loginPassWord,
        }
        this.$api.post('/saAdminChangePassword.sahtml', model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      editAdmin(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "编辑管理员";
        this.cuDialogType = "update";
        this.tableId = obj.tableId;
        this.theName = obj.theName;
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saAdminAdd.sahtml' : '/saAdminUpdate.sahtml';
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          theName: this.theName,
          loginPassWord: this.loginPassWord,
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getAdminList();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      cancel() {
        this.$refs.topDialog.hide();
        this.resetPwdDialogShow = false;
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.theName = "";
        this.loginPassWord = "";
        this.idArr = [];
      },
      cancelPermission(){
        this.permissionDialogShow = false;
        this.tableId = null;
        this.menuCheckList = [];
      },
      addAdmin() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建管理员";
        this.cuDialogType = "create";
      },
      getAdminList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
        }

        this.$api.post('/saAdminList.sahtml', model, jsonObj => {
          console.log("管理员列表");
          console.log(jsonObj);
          this.adminList = jsonObj.adminList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getMenuList(){
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saMenuForSelectList.sahtml', model, jsonObj => {
          console.log("菜单列表");
          console.log(jsonObj);
          this.menuList = jsonObj.menuList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getAdminMenuList(){
        this.menuCheckList = []

        let model = {
          interfaceVersion: 19000101,
          adminId:this.tableId,
        }

        this.$api.post('/saAdminAndMenuRelationList.sahtml', model, jsonObj => {
          console.log("管理员拥有的菜单列表");
          console.log(jsonObj);
          let adminAndMenuRelationList = jsonObj.adminAndMenuRelationList;
          adminAndMenuRelationList.forEach((item) => {
            this.menuCheckList.push(item.menuId)
          })
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      savePermission(){
        let model = {
          interfaceVersion: 19000101,
          adminId: this.tableId,
          idArr: this.menuCheckList,
        }
        this.$api.post('/saAdminAndMenuRelationAdd.sahtml', model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancelPermission()

          this.pageNumber = 1
          this.getAdminList()
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getAdminList();
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saAdminDelete.sahtml', model, jsonObj => {
          if(localStorage.getItem("loginAdminId").indexOf(this.idArr) > -1) {
            localStorage.setItem("loginAdminToken","");
            localStorage.setItem("loginAdminName","");
            localStorage.setItem("loginAdminId","");
            location.href="login.html#/"
          } else {
            this.cancel();
            this.getAdminList();
            this.$message.success(jsonObj.info);
          }
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      delUser(tableId){
        this.$refs.topDialog.show()
        this.idArr.push(tableId);
      }
    }
  }
</script>
<style scoped lang="less">
  .btn-add{
    float: right;
  }
  .menu-list{
    min-width: 145px;
    margin-bottom: 5px;
    display: inline-block;
  }
</style>
