<template>
	<div class="cs-content">
    <h3 class="cs-title">新闻资讯 > <span class="small-text" v-if="cuDialogType == 'update'">资讯编辑</span><span class="small-text" v-if="cuDialogType == 'create'">资讯新增</span></h3>
    <div class="news-content">
      <div class="cs-mb-15">
        <span class="dialog-title">新闻标题：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入新闻标题" size="small" v-model="theTitle"></el-input>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">资讯来源：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入资讯来源" size="small" v-model="theSourceContent"></el-input>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">发布信息：</span>
<!--        <div class="dialog-input">-->
<!--          <el-input placeholder="请输入发布信息" size="small" v-model="publishInfo"></el-input>-->
<!--        </div>-->
        <div class="dialog-input">
          <el-date-picker value-format="yyyy-MM-dd" v-model="publishInfo" type="date" placeholder="选择日期"></el-date-picker>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">上传封面：</span>
        <div class="dialog-input">
          <div class="file-wrap">
            <img src=".././assets/images/upload.png" width="202" v-if="theCoverImage == ''">
            <el-image
                v-else
                style="width: 202px; height: 102px"
                :src="theCoverImage"
                fit="cover"></el-image>
            <input type="file" class="input-file" id="uploadCover"/>
          </div>
          <div class="text-danger">* 上传图片宽高比：13:10，图片大小建议2M以内</div>
          <img v-if="theCoverImage != null && theCoverImage.length > 0" class="img-del" src=".././assets/images/account_ico_delete.png" @click="imageDel"/>
        </div>
      </div>
      <div class="cs-mb-15">
        <span class="dialog-title">新闻内容：</span>
        <div class="dialog-input">
          <div ref="editor" class="cs-editor" />
        </div>
      </div>
      <div class="cs-btn btn-sure" @click="save">保存</div>
    </div>
	</div>
</template>
<script>
  import Vue from 'vue'
  import {Input,DatePicker} from 'element-ui';
  Vue.use(Input)
  Vue.use(DatePicker)
  import E from 'wangeditor'
  import fileUpload from "../assets/js/oss/FileUpload";

  export default {
    name: "newsAdd",
    data() {
      return {
        editor: null,
        tableId: null,
        theTitle: "",
        theCoverImage: "",
        theSourceContent: "",
        publishInfo: "",
        cuDialogType: "create"
      }
    },
    mounted() {
      this.editor = new E(this.$refs.editor)
      this.editor.customConfig.uploadImgShowBase64 = true // 使用 base64 保存图片
      // 不可修改
      this.editor.customConfig.uploadImgHeaders = this.headers
      // 自定义文件名，不可修改，修改后会上传失败
      this.editor.customConfig.uploadFileName = 'file'
      this.editor.customConfig.uploadImgServer = this.uploadApi // 上传图片到服务器
      this.editor.customConfig.uploadImgHooks = {
        customInsert: function(insertImg, result, editor) {
          var url = result.link
          insertImg(url)
        }
      }
      this.editor.customConfig.onchange = (html) => {
        this.info = html
        this.$emit('change', this.info)
        this.$emit('input', this.info)
      }
      this.editor.create()
      this.editor.txt.html(this.value)
      fileUpload.upload('uploadCover', url => {
        this.theCoverImage = url;
      })

      this.tableId = this.$route.query.tableId;
      if(this.tableId > 0) {
        this.cuDialogType = 'update';
        this.getNewsDetail();
      }
    },
    methods: {
      getNewsDetail() {
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
        }

        this.$api.post('/saNewsDetail.sahtml', model, jsonObj => {
          // console.log("新闻详情");
          // console.log(jsonObj);
          this.theTitle = jsonObj.news.theTitle;
          this.publishInfo = jsonObj.news.publishInfo;
          this.theCoverImage = jsonObj.news.theCoverImage;
          this.theSourceContent = jsonObj.news.theSourceContent;

          this.$http.get(jsonObj.news.theContent).then(res => {
            // console.info("读取文件内容")
            // console.info(res)
            var newsContent;
            if(res.body == null)
            {
                if(res.bodyText !=null && res.bodyText.length > 17)
                {
                    newsContent = res.bodyText.substr(15,res.bodyText.length-17);
                }
                else
                {
                    newsContent = "";
                }
            }
            else
            {
                newsContent = res.body.theContent;//此处的res对象包含了json的文件信息和数据，我们需要的json数据存在于body属性中
            }
            this.editor.txt.html(newsContent);
          })
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      imageDel(){
        this.theCoverImage = ""
      },
      reset() {
        this.editor.txt.html('')
      },
      //Percy 用于回显
      setVal(val) {
        this.editor.txt.html(val)
      },
      save() {
        let url = this.cuDialogType == "create" ? '/saNewsAdd.sahtml' : '/saNewsUpdate.sahtml';
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          theTitle: this.theTitle,
          publishInfo: this.publishInfo,
          theCoverImage: this.theCoverImage,
          theSourceContent: this.theSourceContent,
          theContent: this.editor.txt.html(),
          richText: this.editor.txt.text()
        }
        this.$api.post(url, model, jsonObj => {
          // this.$message.success(jsonObj.info);
          // console.log(jsonObj);
          location.href="index.html#/newsList"
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      }
    }
  }
</script>
<style scoped lang="less">
  .file-wrap {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    .input-file {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      font-size: 150px;
      opacity: 0;
    }
  }
  .news-content{
    width: 800px;
    margin-top: 20px;
  }
  .btn-sure{
    margin-left: 90px;
  }
  .img-del{
    position: absolute;
    left: 280px;
    top: -10px;
    width: 20px;
    cursor: pointer;
    z-index: 10;
  }
  .cs-editor{
    position: relative;
    z-index: 1;
  }
</style>
