<template>
	<div class="cs-content">
    <div class="cs-title">地区管理<div class="cs-btn btn-add" @click="addRegion">新建地区</div></div>
    <div class="cs-table">
      <el-table :data="regionList" stripe style="width: 100%;" height="calc(100vh - 232px)">
        <el-table-column type="index" label="序号">
          <template scope="scope">
            <div>{{scope.$index+(pageNumber - 1) * countPerPage + 1}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="theName" label="地区"></el-table-column>
        <el-table-column label="操作">
          <template scope="scope">
            <div class="td-btns text-success" @click="editRegion(scope.row)">编辑</div>
            <div class="line">|</div>
            <div class="td-btns text-danger" @click="delRegion(scope.row.tableId)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cs-page">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="countPerPage"
            layout="prev,pager,next,total,jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <tip-dialog :title="handleTitle" :info="handleInfo" :on-sure-click="sureSave" ref="topDialog"></tip-dialog>
    <el-dialog :title="cuDialogTitle" :visible.sync="cuDialogShow" :show-close=false width="460px">
      <div class="cs-mb-15">
        <span class="dialog-title">地区：</span>
        <div class="dialog-input">
          <el-input placeholder="请输入地区名称" size="small" v-model="theName"></el-input>
        </div>
      </div>
      <div slot="footer">
        <div class="cs-btn btn-cancel" @click="cancel">取消</div>
        <div class="cs-btn btn-sure" @click="sure">确定</div>
      </div>
    </el-dialog>
	</div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Table, TableColumn, Pagination,Dialog} from 'element-ui';
  Vue.use(Input)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Pagination)
  Vue.use(Dialog)

  import TipDialog from ".././components/TipDialog";

  export default {
    name: "regionList",
    components: {TipDialog},
    data() {
      return {
        regionList: [],

        handleTitle:'删除',
        handleInfo:'您确定要删除该地区吗？',

        pageNumber: 1,
        countPerPage:20,
        totalCount:0,
        totalPage: 0,

        tableId: null,
        theName: "",

        cuDialogShow:false,//控制新建编辑弹框是否显示
        cuDialogTitle: "",//新建编辑弹窗标题
        cuDialogType: "",//新建编辑弹窗类型 用于区分是新建还是编辑 create,update

        idArr: []
      }
    },
    mounted() {
      this.getRegionList();
    },
    methods:{
      editRegion(obj) {
        this.cuDialogShow = true;
        this.cuDialogTitle = "编辑地区";
        this.cuDialogType = "update";
        this.tableId = obj.tableId;
        this.theName = obj.theName;
      },
      getRegionList() {
        let model = {
          interfaceVersion: 19000101,
          keyword: this.keyword,
          pageNumber: this.pageNumber,
          countPerPage: this.countPerPage,
        }

        this.$api.post('/saRegionList.sahtml', model, jsonObj => {
          console.log("地区列表");
          console.log(jsonObj);
          this.regionList = jsonObj.regionList;
          this.keyword = jsonObj.keyword;
          this.totalPage = jsonObj.totalPage;
          this.pageNumber = jsonObj.pageNumber;
          this.countPerPage = jsonObj.countPerPage;
          this.totalCount = jsonObj.totalCount;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      addRegion() {
        this.cuDialogShow = true;
        this.cuDialogTitle = "新建地区";
        this.cuDialogType = "create";
      },
      cancel() {
        this.$refs.topDialog.hide();
        this.cuDialogShow = false;
        this.cuDialogTitle = "";
        this.cuDialogType = "";
        this.tableId = null;
        this.theName = "";
        this.idArr = [];
      },
      sure() {
        let url = this.cuDialogType == "create" ? '/saRegionAdd.sahtml' : '/saRegionUpdate.sahtml';
        let model = {
          interfaceVersion: 19000101,
          tableId: this.tableId,
          theName: this.theName,
        }
        this.$api.post(url, model, jsonObj => {
          this.$message.success(jsonObj.info);
          this.cancel();
          this.getRegionList();
        },jsonObj => {
          this.$message.error(jsonObj.info);
          console.log(jsonObj);
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNumber = val;
        this.getRegionList();
      },
      sureSave(){
        let model = {
          interfaceVersion: 19000101,
          idArr: this.idArr,
        }

        this.$api.post('/saRegionDelete.sahtml', model, jsonObj => {
          this.cancel();
          this.getRegionList();
          this.$message.success(jsonObj.info);
        },jsonObj => {
          this.$message.error(jsonObj.info);
        })
      },
      delRegion(tableId){
        this.$refs.topDialog.show()
        this.idArr.push(tableId);
      }
    }
  }
</script>
<style scoped lang="less">
  .btn-add{
    float: right;
    margin-top: -5px;
  }
</style>
