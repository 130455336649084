<template>
  <div>
    <div class="cs-content">
      <h3 class="cs-title">数据统计</h3>
    </div>
    <div class="cs-content rate-wrap">
      <h3 class="title">实验室利用率</h3>
      <div class="cs-search">
        <div class="cs-sel">
          <span class="text">统计日期：</span>
          <div class="cs-ml sel-width">
            <el-date-picker size="small" value-format="yyyy-MM-dd" v-model="searchDate" type="date" placeholder="请选择日期" @change="getLaboratoryList"></el-date-picker>
          </div>
          <span class="text">地区：</span>
          <div class="sel-width">
            <el-select v-model="regionId" filterable placeholder="请选择地区" size="small" @change="getLaboratoryList">
              <el-option
                  v-for="item in regionList"
                  :key="item.tableId"
                  :label="item.theName"
                  :value="item.tableId">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="item-wrap">
        <div class="item-content">
          <div v-for="(item,index) in laboratoryList" :key="index" class="item-list">
            <template v-if="laboratoryList.length > 0">
              <span class="text-num" :style="{color:colors[index]}">{{index + 1}}</span>
              <a class="text-name" :title="item.theName">{{item.theName}}</a>
              <div class="item-right">
                <div class="progress-sum">
                  <div class="progress-rate" :style="{width:item.useRatio + '%'}"></div>
                </div>
                <span class="text-rate">{{item.useRatio}}%</span>
              </div>
            </template>
          </div>
          <div class="no-data" v-if="laboratoryList.length == 0">
            <img src="./nodata.png" width="100">
            <div class="text">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-content chart-wrap">
      <h3 class="title">用户履约率</h3>
      <div class="chart" id="chart1"></div>
    </div>
    <div class="cs-content">
      <h3 class="title">预约时间统计</h3>
      <div class="tab-wrap">
        <ul class="tab-state">
          <li :class="{'active':chartType == 1}" @click="changeChartType(1)">近30天</li>
          <li :class="{'active':chartType == 2}" @click="changeChartType(2)">近20周</li>
          <li :class="{'active':chartType == 3}" @click="changeChartType(3)">近12月</li>
        </ul>
      </div>
      <div class="chart" id="chart2"></div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {Select,Option,DatePicker} from 'element-ui';
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(DatePicker)

  import echarts from 'echarts'
  Vue.prototype.$echarts = echarts

  export default {
    name: "index",
    data() {
      return {
        colors:['#ff3f17','#ff7917','#ffc017'],

        xData: [],
        yData: [],
        chartType: 1,
        xDataFulfillment: [],
        yDataFulfillment: [],
        regionList: [],
        regionId: "",
        laboratoryList: [],
        searchDate: "",
      }
    },
    methods : {
      getNowTime() {
        var now = new Date();
        var year = now.getFullYear(); //得到年份
        var month = now.getMonth(); //得到月份
        var date = now.getDate(); //得到日期
        month = month + 1;
        month = month.toString().padStart(2, "0");
        date = date.toString().padStart(2, "0");
        this.searchDate = `${year}-${month}-${date}`;
      },
      getLaboratoryList() {
        let model = {
          interfaceVersion: 19000101,
          regionId: this.regionId,
          searchDate: this.searchDate,
        }
        this.$api.post('/saLaboratoryUseRatioStatistics.sahtml', model, jsonObj => {
          console.log("实验室列表");
          console.log(jsonObj);
          this.laboratoryList = jsonObj.laboratoryList;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      getRegionList() {
        let model = {
          interfaceVersion: 19000101,
        }

        this.$api.post('/saRegionList.sahtml', model, jsonObj => {
          console.log("地区列表");
          console.log(jsonObj);
          this.regionList = jsonObj.regionList;
          this.regionId = this.regionList[0].tableId;
        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      changeChartType(chartType) {
        this.chartType = chartType;
        this.getInfo();
      },
      getInfo() {
        var that = this;
        let model = {
          interfaceVersion: 19000101,
          chartType: this.chartType,
        }

        this.$api.post('/saAppointmentStatic.sahtml', model, jsonObj => {
          console.log(jsonObj);
          Vue.nextTick(function () {
            var resultData = jsonObj.statisticsList;
            var xData = [];
            var yData = [];
            for (var i=0;i<resultData.length;i++) {
              xData[i] = resultData[i].date;
              yData[i] = resultData[i].count/60;//后端传过来的数据是“分钟”，前端要用的是“小时”
            }
            that.xData = xData;
            that.yData = yData;

            that.drawLine2();
          })
          Vue.nextTick(function () {
            var resultDataFulfillment = jsonObj.fulfillmentRatio;
            var xDataFulfillment = [];
            var yDataFulfillment = [];
            for (var i=0;i<resultDataFulfillment.length;i++) {
              xDataFulfillment[i] = resultDataFulfillment[i].date;
              yDataFulfillment[i] = resultDataFulfillment[i].ratio;
            }
            that.xDataFulfillment = xDataFulfillment;
            that.yDataFulfillment = yDataFulfillment;

            that.drawLine1();
          })

        },jsonObj => {
          // console.log(jsonObj);
          this.$message.error(jsonObj.info);
        })
      },
      myresize() {
        var echarts = require('echarts');
        var rate = echarts.init(document.getElementById('chart1'));
        var time = echarts.init(document.getElementById('chart2'));
        window.onresize = function () {
          rate.resize()
          time.resize()
        }
      },
      drawLine1(){
        var echarts = require('echarts');
        var myChart = echarts.init(document.getElementById('chart1'));
        myChart.setOption({
          color: ['#2c9341'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: '#dcdcdc'
              }
            },
            formatter: '{b0} <br> 履约率：{c0}%'
          },
          grid: {
            left: '25px',
            right: '45px',
            bottom: '0',
            containLabel: true
          },
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: '#666666'
              }
            },
            data: this.xDataFulfillment
          }],
          yAxis: [{
            type: 'value',
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              margin: 10,
              textStyle: {
                fontSize: 13
              },
              formatter:'{value}%'
            },
            splitLine: {
              lineStyle: {
                color: '#f6f6f6'
              }
            }
          }],
          series: [{
            name: '履约率',
            data:this.yDataFulfillment,
            type: 'line',
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(44, 147, 65,0.3)'
              }, {
                offset: 1,
                color: 'rgba(44, 147, 65,0)'
              }])
            },
          }]
        })
      },
      drawLine2(){
        var that = this;
        console.log(that);
        var echarts = require('echarts');
        var myChart = echarts.init(document.getElementById('chart2'));
        myChart.setOption({
          color: ['#2c9341'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: '#dcdcdc'
              }
            },
            formatter: '{b0} <br> 预约时间：{c0}h'
          },
          grid: {
            left: '0px',
            right: '25px',
            bottom: '0',
            containLabel: true
          },
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: '#666666'
              }
            },
            // data: ['5-11', '5-12', '5-13', '5-14', '5-15', '5-16', '5-17']
            data: that.xData
          }],
          yAxis: [{
            type: 'value',
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              margin: 10,
              textStyle: {
                fontSize: 13
              },
              formatter:'{value}h'
            },
            splitLine: {
              lineStyle: {
                color: '#f6f6f6'
              }
            }
          }],
          series: [{
            name:'预约时间',
            // data:[20, 32, 90, 34, 90, 30, 20],
            data: that.yData,
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(44, 147, 65,0.3)'
              }, {
                offset: 1,
                color: 'rgba(44, 147, 65,0)'
              }])
            },
          }]
        })
      }
    },
    mounted(){
      this.myresize();
      this.getInfo();
      this.getRegionList();
      this.getNowTime();
    }
  }

</script>

<style scoped lang="less">
  @import "../assets/css/common.less";
  @progressH:4px;
  @rateW:600px;
  .cs-content{
    margin-bottom: 10px;
    .cs-title{
      margin: 0;
    }
    .title{
      font-size: @fontSize + 1px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 15px;
    }
    &.rate-wrap{
      width: @rateW;
      height: 536px;
      min-height: 536px;
      display: inline-block;
      margin-right: 10px;
    }
    &.chart-wrap{
      width: calc(100% - @rateW - 10px);
      display: inline-block;
      padding-right: 0px;
      padding-left: 0;
      .title{
        margin-left: 25px;
      }
    }
  }
  .progress-sum{
    width: 200px;
    height: @progressH;
    background-color: #eef0f2;
    border-radius: 20px;
    display: inline-block;
    vertical-align: middle;
    .progress-rate{
      height: @progressH;
      background-color: #colors[primary];
      border-radius: 20px;
    }
  }
  .item-wrap{
    overflow-x: hidden;
    .item-content{
      width: @rateW - @contentP*2 + 20px;
      max-height: 435px;
      overflow-y: auto;
    }
    .item-list{
      margin: 15px 0;
      .text-name{
        display: inline-block;
        max-width: 230px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    .text-rate{
      font-size: 13px;
      color: #colors[primary];
      width: 60px;
      display: inline-block;
      text-align: right;
    }
    .text-num{
      font-size: 16px;
      font-weight: 600;
      color: #999999;
      font-style: italic;
      display: inline-block;
      width: 35px;
      vertical-align: middle;
    }
    .item-right{
      float: right;
      padding-right: 22px;
    }
    .no-data{
      text-align: center;
      width: 550px;
      margin-top: 120px;
      .text{
        margin-top: 10px;
        color: #666;
      }
    }
  }
  .chart{
    height: 450px;
  }
  .tab-wrap{
    border-bottom: 1px solid #e4e4e4;
    background-color: #f7f7f7;
    padding: 0 30px;
    .tab-state{
      display: #display[i];
      li{
        display: #display[i];
        color: #colors[defaultText];
        padding: 15px 10px 15px 10px;
        margin-right: 40px;
        cursor: pointer;
        position: relative;
      }
      .active{
        color: #colors[primary];
        border-bottom: 2px solid #colors[primary];
      }
    }
  }
</style>
<style>
  .sel-width .el-date-editor.el-input{
    width: 100%;
  }
</style>
